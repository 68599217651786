<template>
  <div>

    <van-nav-bar :title="$t('message.shopcar')"
                 :left-text="showBack?$t('message.back'):null"
                 :left-arrow="showBack"
                 @click-left="$router.back()"
    />

    <van-checkbox-group v-model="checkedGoodList">
      <van-empty description="购物车空空如也" v-if="goodList.length===0"/>
      <van-cell v-for="(e,i) in goodList" :key="i">
        <van-checkbox :name="i">
          <van-card
              :num="e.goodAmount"
              :price="e.goodPrice"
              :desc="e.propName+';'+e.specsName"
              :title="e.goodName"
              :thumb="e.imgUrl"
              @click.stop.native
          >
            <template #num>
              <van-stepper v-model="goodList[i].goodAmount" style="display: inline-block" @click.stop.native
                           @change="stepperChange" :name="e.goodDetailId"/>
            </template>
            <div></div>
          </van-card>
        </van-checkbox>
      </van-cell>
    </van-checkbox-group>

    <van-button v-if="goodList.length!==0" block type="primary" @click="confirmMultipleOrder">{{$t('message.confirmOrder')}}</van-button>
    <van-button v-if="goodList.length!==0" block type="danger" @click="deleteGood">{{$t('message.deleteGood')}}</van-button>

  </div>
</template>

<script>
import axios from "axios";
// import {Toast} from "vant";

export default {
  name: "ShopcarView",
  data() {
    return {
      value: 12,
      checkedGoodList: [],

      goodAmount: {},

      shopcarGood: [],

      goodList: [
        // {
        //   goodId: 1,
        //   goodName: '三只松鼠饼干',
        //   goodStock: 6,
        // },
        // {
        //   goodId: 2,
        //   goodName: 'iphoneX',
        //   goodStock: 10,
        // },
        // {
        //   goodId: 3,
        //   goodName: '垃圾袋',
        //   goodStock: 10,
        // },
      ],
      showBack: false,
    }
  },
  created() {
    axios.get('/app/verifyJwt')
    if (this.$route.query.showBack) {
      this.showBack = true;
    }
    //查询用户购物车内所有商品
    axios({
      method: 'get',
      url: '/app/shopcar/selectAll'
    }).then(res => {
      console.log(res.data)
      for (let e of res.data.data) {
        e.imgUrl = `${axios.defaults.baseURL}/img/download/${e.imgUrl}`
      }
      this.goodList = res.data.data
    })

  },
  methods: {
    stepperChange: function (v, e) {
      //购物车商品购买数量变化
      //console.log('goodDetailId为' + e.name+ '的数量变成了' + v)
      axios({
        method: 'put',
        url: `/app/shopcar/updateAmount/${e.name}/${v}`,
      })
    },
    confirmMultipleOrder: function () {
      console.log('多商品订单')

      // let buyDetail = JSON.stringify(
      //     [
      //       {
      //         goodDetailId: this.goodDetailId,
      //         buyAmount: this.buyAmount,
      //         goodId: this.goodId,
      //         goodPrice: this.goodPrice,
      //         goodProp: this.choosedProp,
      //         goodSpecs: this.choosedSpecs,
      //         goodKindId: this.goodDetail.goodKindId,
      //       }
      //     ])

      let buyDetail = [];
      for (let i of this.checkedGoodList) {
        buyDetail.push({
          goodDetailId:this.goodList[i].goodDetailId,
          buyAmount: this.goodList[i].goodAmount,
          goodId: this.goodList[i].goodId,
          goodPrice: this.goodList[i].goodPrice,
          goodProp: this.goodList[i].propName,
          goodSpecs: this.goodList[i].specsName,
          goodKindId: this.goodList[i].goodKindId,
        })
      }
      buyDetail=JSON.stringify(buyDetail)
      console.log(buyDetail)
      this.$router.push({path:'/confirmOrder',query:{buyDetail,deleteFromShopcar:'yes'}})
    },
    deleteGood:function () {
      for (let i = 0; i < this.checkedGoodList.length; i++) {
        console.log('删除',this.goodList[this.checkedGoodList[i]].goodDetailId)
        axios({
          method:'delete',
          url:`/app/shopcar/delete/${this.goodList[this.checkedGoodList[i]].goodDetailId}`
        }).then(() =>{
          axios({
            method: 'get',
            url: '/app/shopcar/selectAll'
          }).then(res => {
            console.log(res.data)
            for (let e of res.data.data) {
              e.imgUrl = `${axios.defaults.baseURL}/img/download/${e.imgUrl}`
            }
            this.goodList = res.data.data
          })
        })
      }
    }
  },
  watch: {
    checkedGoodList: function (nv) {
      console.log('选中的',nv)
    },
    goodAmount: {
      handler: function (nv) {
        console.log(nv)
      },
      deep: true
    },
    shopcarGood: function (nv) {
      console.log(nv)
    },
    // goodList:{
    //   handler:function (nv,ov) {
    //     let s1=JSON.stringify(nv)
    //     let s2=JSON.stringify(ov)
    //     console.log(s1==s2)
    //     console.log(nv,ov)
    //   },
    //   deep:true,
    // }
  }
}
</script>

<style scoped>

</style>
