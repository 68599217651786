<template>

  <div>
    <van-nav-bar :title="$t('message.vip')"/>


<!--    <van-cell-group>-->
<!--      <van-cell >-->
<!--        <div style="text-align: center">-->
<!--          <van-image width="100px" :src="require('../assets/user.png')" ></van-image>-->
<!--        </div>-->
<!--      </van-cell>-->
<!--    </van-cell-group>-->

    <van-cell :title="$t('message.vipLevel')+':'"/>
    <van-grid column-num="1" :border="false">
      <van-grid-item><van-image width="100px" :src="isVip?require('../assets/vip.png'):require('../assets/user.png')"></van-image></van-grid-item>
    </van-grid>

    <van-cell :title="$t('message.membershipValidUntil')+':'" v-if="this.isVip"/>
    <van-grid column-num="1" :border="false" v-if="this.isVip">
      <van-grid-item><p style="font-size: small;color: red">{{vipEndTime}}</p></van-grid-item>
    </van-grid>

    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell :title="$t('message.monthly')" clickable @click="radio = '月度'">
          <template #right-icon>
            <van-radio name="月度" />
          </template>
        </van-cell>
        <van-cell :title="$t('message.quarter')" clickable @click="radio = '季度'">
          <template #right-icon>
            <van-radio name="季度" />
          </template>
        </van-cell>
        <van-cell :title="$t('message.yearly')" clickable @click="radio = '年度'">
          <template #right-icon>
            <van-radio name="年度" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <h2 style="text-align: center">
      ¥ {{vipPrice}}
    </h2>
    <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" :text="isVip?$t('message.renewVip'):$t('message.openVip')" block @click="openOrRenewVip"/>

  </div>




</template>

<script>
import axios from "axios";
import {Dialog} from "vant";

export default {
  name: "VipView",
  data(){
    return {
      radio:'月度',

      isVip:false,

      vipObj:{}


    }
  },
  computed:{
    vipPrice:function () {
      if (this.radio == '月度') {
        return 25
      } else if (this.radio == '季度') {
        return 60
      } else if (this.radio == '年度') {
        return 238
      }
      return 0
    },
    vipEndTime(){
      let e=Array.from(this.vipObj.vipEndTime)
      return `${e[0]}${e[1]}${e[2]}${e[3]} 年 ${e[5]}${e[6]} 月 ${e[8]}${e[9]} 日 ${e[11]}${e[12]} 时 ${e[14]}${e[15]} 分 ${e[17]}${e[18]} 秒`
    }
  },
  methods:{
    openOrRenewVip:function () {
      console.log('开通会员')
      Dialog.confirm({
        title: '提示',
        message: `确认要${this.isVip?'续费会员':'开通会员'}吗?`,
      }).then(() => {
            // on confirm
        let buyDetail=JSON.stringify(
            [
              {
                goodId:99,
                goodDetailId:99,
                buyAmount:1,
                goodPrice:this.vipPrice,
                deleteFromShopcar:'no',
              }
            ])
        this.$router.push({path:'/confirmOrder',query:{buyDetail,type:'vip'}})
      }).catch(() => {
            // on cancel
      });
    }
  },
  created() {
    axios.get('/app/verifyJwt')

    axios.get('/app/vip/getVip').then(res=>{
      if (res.data.code==250){
        console.log('非会员')
      }else{
        if (new Date(res.data.data.useVip.vipEndTime).getTime()>Date.now()){
          this.isVip=true
          this.vipObj=res.data.data.useVip
          console.log(this.vipObj)

        }
      }
    })
  }
}
</script>

<style scoped>

</style>
