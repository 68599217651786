<template>
  <div>
    <van-nav-bar
        :title="$t('message.pay')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="back"
    />

    <van-grid column-num="1" :border="false">
      <van-grid-item :text="$t('message.paymentAmount')"/>
      <van-grid-item style="font-size: xx-large">¥ {{ orderPrice }}</van-grid-item>
    </van-grid>

    <van-radio-group v-model="payMethod">
      <van-cell-group>
        <van-cell :title="$t('message.alipay')" clickable>
          <template #right-icon>
            <van-radio name="支付宝"/>
          </template>
        </van-cell>
        <van-cell :title="$t('message.weChat')" clickable>
          <template #right-icon>
            <van-radio name="微信"/>
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <van-cell>
      <van-button type="primary" block @click="confirmPay">
        {{ $t('message.confirmPay') }}
      </van-button>
    </van-cell>
  </div>
</template>

<script>
import {Toast} from "vant";
import axios from "axios";

export default {
  name: "PayView",
  data() {
    return {
      value: '',
      errorInfo: '',
      showKeyboard: true,

      payMethod: '支付宝',

      orderId: '',
      orderPrice: '',
    };
  },
  methods: {
    confirmPay: function () {
      console.log('确认支付');

      if (this.payMethod == '微信') {
        Toast.fail(this.$t('message.weChatPaymentIsCurrentlyUnavailable'))
        return
      }
      // window.open("http://localhost:9999/alipay/pay?subject=" + this.payFrom2.goodName + "&traceNo=" + this.payFrom2.orderId + "&totalAmount=" + this.payFrom2.orderPrice)
      location.href=axios.defaults.baseURL + "/pay/alipay/pay?subject=123&traceNo=" + this.orderId + "&totalAmount=" + this.orderPrice
      // Toast.success('支付成功');
    },
    back: function () {
      if (this.goodDetailId==99){
        console.log('取消订单',this.orderId)
        axios({
          method:'delete',
          url:'/order/order/canceMember',
          params:{
            orderId:this.orderId
          }
        }).then(res=>{
          console.log(res.data)
        })
        this.$router.back()
      }
      this.$router.back()

    },
  },
  created() {

    this.orderId = this.$route.query.orderId;
    this.orderPrice = this.$route.query.orderPrice;
    this.goodId = this.$route.query.goodId;
    this.goodDetailId = this.$route.query.goodDetailId;
    console.log('订单价格'+this.orderPrice)
  }
}
</script>

<style scoped>

</style>
