import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IndexView from "@/views/IndexView";
import LoginView from "@/views/LoginView";
import MineView from "@/views/MineView";
import VipView from "@/views/VipView";
import MessageView from "@/views/MessageView";
import ShopcarView from "@/views/ShopcarView";
import RegistView from "@/views/RegistView";
import ActivityView from "@/views/ActivityView";
import GoodDetailView from "@/views/GoodDetailView";
import AddressView from "@/views/AddressView";
import CouponView from "@/views/CouponView"
import OrderDetailView from "@/views/OrderDetailView";
import PayView from "@/views/PayView";
import AddressDetailView from "@/views/AddressDetailView";
import ObligationView from "@/views/ObligationView";
import ConfirmOrderView from "@/views/ConfirmOrderView";
import PersonalSettingView from "@/views/PersonalSettingView";
import SearchView from "@/views/SearchView";
import PaidView from "@/views/PaidView";
import WaitingView from "@/views/WaitingView";
import LogisticsView from "@/views/LogisticsView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: HomeView,
        redirect: '/index',
        children: [
            {
                path: '/index',
                component: IndexView,
            },
            {
                path: '/message',
                component: MessageView,
                meta: {loginAuth: true}
            },
            {
                path: '/shopcar',
                component: ShopcarView,
                meta: {loginAuth: true}
            },
            {
                path: '/vip',
                component: VipView,
                meta: {loginAuth: true}
            },
            {
                path: '/mine',
                component: MineView,
            },

        ],
    },
    {
        path: '/login',
        component: LoginView,
    },
    {
        path: '/regist',
        component: RegistView,
    },
    {
        path: '/activity',
        component: ActivityView,
    },
    {
        path: '/goodDetail',
        component: GoodDetailView,
    },
    {
        path: '/address',
        component: AddressView,
    },
    {
        path: '/coupons',
        component: CouponView,
    },
    {
        path: '/addressDetail',
        component: AddressDetailView,
    },
    {
        path: '/orderDetail',
        component: OrderDetailView,
    },
    {
        path: '/pay',
        component: PayView,
    },
    {
        path:'/obligation',
        component: ObligationView
    },
    {
        path:'/confirmOrder',
        component: ConfirmOrderView
    },
    {
        path:'/personalSetting',
        component: PersonalSettingView
    },
    {
        path:'/search',
        component: SearchView
    },
    {
        path:'/paid',
        component: PaidView
    },
    {
        path:'/waiting',
        component: WaitingView
    },
    {
        path:'/logistics',
        component: LogisticsView
    },
]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
    routes
})


export default router
