<template>
  <div>

    <van-nav-bar
        :title="$t('message.waiting')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
    <div v-for="(e,i) in waiting" :key="i">
      <!--        <van-divider />-->
      <van-cell>{{$t('message.orderId')}}:{{e[0].orderId}}</van-cell>
      <van-grid column-num="2" :border="false" v-for="(e1,i1) in e" :key="i1">
        <van-grid-item>
          <van-image :src="baseURL+'/img/download/'+e1.imgUrl" width="100px"></van-image>
        </van-grid-item>
        <van-grid-item>x {{e1.goodAmount}}</van-grid-item>
      </van-grid>
      <div style="text-align: right">
        <!--        <van-button round type="danger" size="small" @click="cancelOrder(e[0].orderId)">取消订单</van-button>-->
        <van-button round type="info" size="small" @click="$router.push({path:'/logistics',query:{orderId:e[0].orderId}})">{{$t('message.viewLogistics')}}</van-button>
        <!--        <van-button round type="primary" size="small" @click="$router.push({path:'/pay',query:{orderId:e[0].orderId,orderPrice:e[0].orderPrice}})">支付订单</van-button>-->
      </div>
    </div>
    </van-list>
    <van-empty description="这里空空如也" v-if="waiting.length===0"/>


  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WaitingView",
  data() {
    return {
      waiting:[],
      baseURL:axios.defaults.baseURL,
      loading:true,
      finished:true,
      pageNum:1,
    }
  },
  methods: {
    onLoad:function () {
      console.log('触底加载')
      axios({
        method:'get',
        url:`/order/order/${++this.pageNum}/10/3`
      }).then(res=>{
        for (let i = res.data.data.length - 1; i >= 0; i--) {
          if (res.data.data[i].length==0){
            res.data.data.splice(i,1);
          }
        }
        for (let e of res.data.data) {
          this.waiting.push(e)
        }
        this.loading=false
        if (res.data.data.length!==10){
          this.finished=true
        }
      })
    },
  },
  created() {
    axios({
      method:'get',
      url:`/order/order/${this.pageNum}/10/3`,
    }).then(res=>{
      console.log(res.data.data)
      for (let i = res.data.data.length - 1; i >= 0; i--) {
        if (res.data.data[i].length===0){
          res.data.data.splice(i,1);
        }
      }
      this.waiting=res.data.data
      this.finished=false
      this.loading=false
    })
  }
}
</script>

<style scoped>

</style>
