<template>
  <div>

    <van-nav-bar
        :title="$t('message.goodDetail')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <!--    <van-swipe @change="onChange" height="200">-->
    <!--      <van-swipe-item style="text-align: center">-->
    <!--        <van-image :src="imgUrl" height="200"></van-image>-->
    <!--      </van-swipe-item>-->
    <!--      <van-swipe-item>-->
    <!--        <van-image :src="imgUrl"></van-image>-->
    <!--      </van-swipe-item>-->
    <!--      <van-swipe-item>-->
    <!--        <van-image src="https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"></van-image>-->
    <!--      </van-swipe-item>-->
    <!--      <van-swipe-item>-->
    <!--        <van-image src="https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"></van-image>-->
    <!--      </van-swipe-item>-->
    <!--      <template #indicator>-->
    <!--        <div class="custom-indicator" style="color: white">{{ current + 1 }}/4</div>-->
    <!--      </template>-->
    <!--    </van-swipe>-->

    <van-swipe @change="onChange" height="200">
      <van-swipe-item style="text-align: center">
        <van-image :src="imgUrl" height="200"></van-image>
      </van-swipe-item>
    </van-swipe>

<!--    <van-cell>-->
<!--      <van-grid column-num="3" :border="false">-->
<!--        &lt;!&ndash;        <van-grid-item v-if="activityId==null">价格 ¥{{ goodDetail.goodPrice }}</van-grid-item>&ndash;&gt;-->
<!--        <van-grid-item>{{$t('message.goodView')}} {{ goodDetail.goodView }}</van-grid-item>-->
<!--      </van-grid>-->
<!--    </van-cell>-->

    <van-cell>{{$t('message.goodName')}}:{{ goodDetail.goodName }}</van-cell>

    <van-cell>{{$t('message.brandName')}}:{{ goodDetail.brandName }}</van-cell>

    <!--    <van-cell>库存:{{ goodDetail.goodStock }}</van-cell>-->

    <van-cell>{{$t('message.goodDesc')}}:{{ goodDetail.goodDesc }}</van-cell>

<!--    <van-cell>{{$t('message.goodView')}}:{{ goodDetail.goodView }}</van-cell>-->
<!--    <van-cell>-->
<!--      <span>宝贝评价(99)</span>-->
<!--      <span style="float: right"><van-button size="mini" color="white"><span-->
<!--          style="color: orange">查看更多 ></span></van-button></span>-->
<!--    </van-cell>-->

    <van-grid column-num="1" :border="false">
      <van-grid-item v-for="(e,i) in goodDetailImgs" :key="i">
        <van-image :src="baseURL+'/img/download/'+e.imgUrl"></van-image>
      </van-grid-item>
    </van-grid>


    <van-goods-action>
      <van-goods-action-icon icon="chat-o" :text="$t('message.chat')" color="#ee0a24"
                             @click="$router.push({path:'/message',query:{showBack:'true'}})"/>
      <van-goods-action-icon icon="cart-o" :text="$t('message.shopcar')" v-if="type=='normal'"
                             @click="$router.push({path:'/shopcar',query:{showBack:'true'}})"/>
      <van-goods-action-icon icon="star" :text="isStared?$t('message.stared'):$t('message.star')" :color="isStared?'orange':'gray'"
                             @click="starGood" v-if="goodId!=null"/>
      <van-goods-action-button type="warning" :text="$t('message.addToGoodcar')" v-if="isAddGoodCarShow" @click="addToGoodcar"/>
      <van-goods-action-button type="danger" :text="text1"
                               @click="buyOrSeckill"/>
      <!--      @click="$router.push({path:'/orderDetail',query:{activityId:activityId}})"-->
    </van-goods-action>

    <van-popup v-model="choosePropSpecs" position="bottom" :style="{ height: '70%' }">
      <van-cell :border="false">{{$t('message.property')}}</van-cell>
      <van-grid column-num="4" :border="false">
        <van-grid-item v-for="(e,i) in propList" :key="i">
          <van-button :type="choosedProp==e?'primary':'default'" @click="choosedProp=e">{{ propName[e] }}</van-button>
        </van-grid-item>
      </van-grid>
      <van-cell :border="false">{{$t('message.specs')}}</van-cell>
      <van-grid column-num="4" :border="false">
        <van-grid-item v-for="(e,i) in specsList" :key="i">
          <van-button :type="choosedSpecs==e?'primary':'default'" @click="choosedSpecs=e">{{
              specsName[e]
            }}
          </van-button>
        </van-grid-item>
      </van-grid>
      <van-cell :border="false">{{$t('message.stock')}}</van-cell>
      <van-grid column-num="1" :border="false">
        <van-grid-item>
          <p style="font-size: small">{{ goodStock }}</p>
        </van-grid-item>
      </van-grid>
      <van-cell :border="false">{{$t('message.amount')}}</van-cell>
      <van-grid column-num="1" :border="false">
        <van-grid-item>
          <van-stepper v-model="buyAmount" theme="round" disable-input :max="goodStock"
                       :disabled="type=='seckill'||type=='activity'?true:false"/>
        </van-grid-item>
      </van-grid>
      <van-cell :border="false">{{$t('message.price')}}:</van-cell>
      <van-grid column-num="1" :border="false">
        <van-grid-item>
          <p style="font-size: small">{{ goodPrice }}</p>
        </van-grid-item>
      </van-grid>
      <van-cell :border="false">{{$t('message.totalPrice')}}:</van-cell>
      <van-grid column-num="1" :border="false">
        <van-grid-item>
          <p style="font-size: small">{{ goodPrice * buyAmount }}</p>
        </van-grid-item>
      </van-grid>
      <van-button block :text="$t('message.confirm')" type="danger" @click="confirmChoosedPropAndSpecs"></van-button>

    </van-popup>




  </div>
</template>

<script>
import axios from "axios";
import {Toast} from "vant";
import store from "@/store";

export default {
  name: "GoodDetailView",
  data() {
    return {
      current: 0,
      //是否收藏?
      isStared: false,
      goodId: null,
      goodDetail: {
        goodDetailList: [],
      },
      isAddGoodCarShow: true,
      text1: this.$t('message.buyNow'),
      //这个界面的活动id
      activityId: null,
      //怎么来这里的 这是SM界面秒杀还是普通商品
      type: null,
      //显示属性和规格
      choosePropSpecs: false,
      //购买数量
      buyAmount: 1,
      //选择的属性的ID
      choosedProp: 0,
      //选择的规格的ID
      choosedSpecs: 0,

      propList: [],
      specsList: [],

      propName: {},
      specsName: {},

      goodOperation: '加入购物车',
      // goodDetailId:null,
      contentId: '',

      goodDetailImgs:[],

      baseURL:axios.defaults.baseURL
    };
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    starGood: function () {
      this.isStared = !this.isStared
      axios.post(`/app/user/userStar/${this.goodId}`).then(res => {
        if (res.data.code == 0) {
          Toast.success(res.data.msg)
          this.isStared = false;
        } else {
          Toast.success(res.data.msg)
          this.isStared = true;
        }
      })
    },
    //秒杀和立即购买按钮
    buyOrSeckill: function () {
      if (this.type == 'seckill') {
        console.log('秒杀')
        this.goodOperation = '秒杀'
        this.choosePropSpecs = true;
        console.log('购买的是' + this.goodDetailId)
      } else if (this.type == 'normal') {
        console.log('立即购买')
        this.goodOperation = '立即购买'
        this.choosePropSpecs = true;
        console.log('购买的是' + this.goodDetailId)
      } else if (this.type == 'activity') {
        console.log('活动购买')
        this.goodOperation = '活动购买'
        this.choosePropSpecs = true;
        console.log('购买的是' + this.goodDetailId)
      }
    },
    addToGoodcar: function () {
      this.goodOperation = '加入购物车'
      this.choosePropSpecs = true;
    },

    //确认按钮
    confirmChoosedPropAndSpecs: function () {
      if (this.goodStock == 0) {
        Toast.fail(this.$t('message.notEnoughStock'))
        return
      }


      if (this.goodOperation == '立即购买') {
        console.log('购买的是' + this.goodDetailId)
        let buyDetail = JSON.stringify(
            [
              {
                goodDetailId: this.goodDetailId,
                buyAmount: this.buyAmount,
                goodId: this.goodId,
                goodPrice: this.goodPrice,
                goodProp: this.choosedProp,
                goodSpecs: this.choosedSpecs,
                goodKindId: this.goodDetail.goodKindId,
              }
            ])
        this.$router.push({path: '/confirmOrder', query: {buyDetail, deleteFromShopcar: 'no'}})
      } else if (this.goodOperation == '加入购物车') {
        console.log('加入购物车')
        axios({
          method: 'post',
          url: `/app/shopcar/addGood/${this.goodDetailId}/${this.buyAmount}`
        }).then(res => {
          if (res.data.code == 200) {
            Toast.success(res.data.msg)
          }
        })
        this.choosePropSpecs = false;
      } else if (this.goodOperation == '秒杀') {
        console.log('秒杀商品')
        let buyDetail = JSON.stringify(
            [
              {
                goodDetailId: this.goodDetailId,
                buyAmount: this.buyAmount,
                goodId: this.goodId,
                goodPrice: this.goodPrice,
                goodProp: this.choosedProp,
                goodSpecs: this.choosedSpecs,
                goodKindId: this.goodDetail.goodKindId,
              }
            ])
        this.$router.push({
          path: '/confirmOrder',
          query: {buyDetail, deleteFromShopcar: 'no', seckillOrder: 'yes', type: this.type, contentId: this.contentId}
        })
      } else if (this.goodOperation == '活动购买') {
        console.log('活动购买')
        let buyDetail = JSON.stringify(
            [
              {
                goodDetailId: this.goodDetailId,
                buyAmount: this.buyAmount,
                goodId: this.goodId,
                goodPrice: this.goodPrice,
                goodProp: this.choosedProp,
                goodSpecs: this.choosedSpecs,
                goodKindId: this.goodDetail.goodKindId,
              }
            ])
        this.$router.push({
          path: '/confirmOrder',
          query: {buyDetail, deleteFromShopcar: 'no', seckillOrder: 'yes', type: this.type, contentId: this.contentId}
        })
      }

    },
  },

  computed: {
    imgUrl: function () {
      if (!this.goodDetail.imgUrl) {
        return require('/src/assets/loading.gif')
      }
      return `${axios.defaults.baseURL}/img/download/${this.goodDetail.imgUrl}`
    },
    goodStock: function () {
      for (let e of this.goodDetail.goodDetailList) {
        if (e.propId == this.choosedProp && e.specsId == this.choosedSpecs) {
          return e.goodStock;
        }
      }
      return 0;
    },
    goodPrice: function () {
      for (let e of this.goodDetail.goodDetailList) {
        if (e.propId == this.choosedProp && e.specsId == this.choosedSpecs) {
          return e.goodPrice;
        }
      }
      return 0;
    },
    goodDetailId: function () {
      for (let e of this.goodDetail.goodDetailList) {
        if (e.propId == this.choosedProp && e.specsId == this.choosedSpecs) {
          return e.goodDetailId;
        }
      }
      return 0;
    }
  },

  created() {
    this.type = this.$route.query.type;
    this.goodId = this.$route.query.goodId;
    this.activityId = this.$route.query.activityId;
    this.contentId = this.$route.query.contentId;
    console.log(this.$route.query)

    //展示该goodId的图片
    axios.get(`/app//GoodDetailImg/${this.goodId}`).then(res => {
      // console.log('图片', res.data)
      this.goodDetailImgs=res.data.data
    })

    if (store.getters.getLoginState){
      //用户是否收藏了该商品
      axios.get(`/app/user/userIsStar/${this.goodId}`).then(res => {
        if (res.data.code == 0) {
          this.isStared = false;
        } else {
          this.isStared = true;
        }
      })
    }

    //秒杀
    if (this.type == 'seckill') {
      console.log('秒杀商品界面')
      this.activityId = this.$route.query.activityId;
      // this.goodDetailId=this.$route.query.goodDetailId;
      this.isAddGoodCarShow = false;
      this.text1 = '秒杀';
      axios.get(`/app/activity/selectActivityDetailVo/${this.goodId}/${this.$route.query.goodDetailId}`).then(res => {
        console.log(res.data)
        this.goodDetail = res.data.data
        let propArr = [];
        for (let e of res.data.data.goodDetailList) {
          propArr.push(e.propId)
          this.propName[e.propId] = e.propName
        }
        let specsArr = [];
        for (let e of res.data.data.goodDetailList) {
          specsArr.push(e.specsId)
          this.specsName[e.specsId] = e.specsName
        }
        //设价格为0
        for (let e of res.data.data.goodDetailList) {
          e.goodPrice = 0
        }
        this.propList = Array.from(new Set(propArr));
        this.specsList = Array.from(new Set(specsArr));
        this.choosedProp = this.propList[0];
        this.choosedSpecs = this.specsList[0];
      })
    } else if (this.type == 'normal') {
      console.log('普通商品界面')
      axios.get(`/app/good/selectGoodDetailVo/${this.goodId}`).then(res => {
        console.log(res.data)
        // res.data.data.imgUrl = 'e77de31c4ec541e0bffe16a9b06d19d0_AD0I4r75CRAEGAAgqfLI7gUoqP2Z5AcwzgI40QQ.png'
        this.goodDetail = res.data.data
        let propArr = [];
        for (let e of res.data.data.goodDetailList) {
          propArr.push(e.propId)
          this.propName[e.propId] = e.propName
        }
        let specsArr = [];
        for (let e of res.data.data.goodDetailList) {
          specsArr.push(e.specsId)
          this.specsName[e.specsId] = e.specsName
        }
        this.propList = Array.from(new Set(propArr));
        this.specsList = Array.from(new Set(specsArr));
        this.choosedProp = this.propList[0];
        this.choosedSpecs = this.specsList[0];
        // console.log(this.propList)
        // console.log(this.propName)
        // console.log(this.specsList)
        // console.log(this.specsName)
      })

    } else if (this.type == 'activity') {
      console.log('活动商品界面')
      this.isAddGoodCarShow = false;
      axios.get(`/app/activity/selectActivityDetailVo/${this.goodId}/${this.$route.query.goodDetailId}`).then(res => {
        this.goodDetail = res.data.data
        let propArr = [];
        for (let e of res.data.data.goodDetailList) {
          propArr.push(e.propId)
          this.propName[e.propId] = e.propName
        }
        let specsArr = [];
        for (let e of res.data.data.goodDetailList) {
          specsArr.push(e.specsId)
          this.specsName[e.specsId] = e.specsName
        }
        //查询获得活动商品价格
        for (let e of res.data.data.goodDetailList) {
          // console.log(e)
          axios({
            method: 'get',
            url: `/app/activity/selectActivityPrice/${this.activityId}`
          }).then(res => {
            // console.log('活动商品价格')
            // console.log(res.data)
            e.goodPrice = res.data.data.activityPrice
          })
        }
        this.propList = Array.from(new Set(propArr));
        this.specsList = Array.from(new Set(specsArr));
        this.choosedProp = this.propList[0];
        this.choosedSpecs = this.specsList[0];
      })
    }


  },
}
</script>

<style>
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
