<template>
  <div>
    <van-nav-bar
        title="消息"
        :left-text="showBack?'返回':null"
        :left-arrow="showBack"
        @click-left="$router.back()"
    />
    <!--    <van-list v-for="(item,index) in infoList" :key="index">-->
    <!--      <van-cell>-->
    <!--        <van-col span="5">-->
    <!--          <van-image-->
    <!--              width="50"-->
    <!--              height="50"-->
    <!--              :src="item.images"-->
    <!--              :round="true"-->
    <!--          />-->
    <!--        </van-col>-->
    <!--        <van-col>-->
    <!--          <div>{{ item.name }}</div>-->
    <!--          <div>{{ item.message }}</div>-->
    <!--        </van-col>-->
    <!--      </van-cell>-->
    <!--    </van-list>-->


    <van-list v-for="(item,index) in infoList" :key="index+50" ref="chatContent">
      <van-cell>

        <van-col style="text-align: right" span="18">
          <div>{{ item.name }}</div>
          <div>{{ item.message }}</div>
        </van-col>
        <van-col span="5" style="float: right">
          <van-image
              width="50"
              height="50"
              :src="item.images"
              :round="true"
          />
        </van-col>
      </van-cell>
    </van-list>

    <van-cell>
      <van-field v-model="messages" type="textarea" placeholder="请输入咨询信息"/>
      <template #right-icon>
        <van-button class="sendOut" type="primary" @click="getSends">发送消息</van-button>
      </template>
    </van-cell>
  </div>


</template>

<script>


import {Toast} from "vant";
import axios from "axios";

let infoData = [
  {
    id: 1,
    name: '小巴',
    time: '2021-4-23 17:59:50 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '您好！'
  },
  {
    id: 3,
    name: '商客',
    time: '2021-4-23 18:00:24 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '请问又是什么需要帮助的吗？'
  },
  {
    id: 2,
    name: '发哥',
    time: '2021-4-23 18:00:05 星期五',
    images: 'https://img01.yzcdn.cn/vant/leaf.jpg',
    message: '咨询信息'
  },
  {
    id: 1,
    name: '小巴',
    time: '2021-4-23 17:59:50 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '您好！'
  },
  {
    id: 3,
    name: '商客',
    time: '2021-4-23 18:00:24 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '请问又是什么需要帮助的吗？'
  },
  {
    id: 2,
    name: '发哥',
    time: '2021-4-23 18:00:05 星期五',
    images: 'https://img01.yzcdn.cn/vant/leaf.jpg',
    message: '咨询信息'
  },
  {
    id: 1,
    name: '小巴',
    time: '2021-4-23 17:59:50 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '您好！'
  },
  {
    id: 3,
    name: '商客',
    time: '2021-4-23 18:00:24 星期五',
    images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
    message: '请问又是什么需要帮助的吗？'
  },
  {
    id: 2,
    name: '发哥',
    time: '2021-4-23 18:00:05 星期五',
    images: 'https://img01.yzcdn.cn/vant/leaf.jpg',
    message: '咨询信息'
  },
]


export default {
  name: "MessageView",
  data() {
    return {
      messages: '',
      nowDate: "", // 当前日期
      current: 2,
      infoList: infoData,
      showBack:false,
    }
  },

  methods: {
    getSend() {
      if (this.messages == '') {
        Toast('请输入咨询信息')
        return
      }
      let userData = {
        id: 2,
        name: '发哥',
        time: this.nowDate,
        images: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        message: this.messages
      }
      infoData.push(userData)
      this.messages = ''
    },
    getSends() {
      if (this.messages == '') {
        Toast('请输入咨询信息')
        return
      }
      let customerData = {
        id: 4,
        name: '小巴',
        time: this.nowDate,
        images: 'https://img01.yzcdn.cn/vant/cat.jpeg',
        message: this.messages
      }
      infoData.push(customerData)
      this.messages = ''

      // console.log('底部')
      // this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;

    },

  },
  created() {
    axios.get('/app/verifyJwt')

    if (this.$route.query.showBack) {
      this.showBack = true;
    }
  }


}
</script>
