<template>
  <div>
    <van-nav-bar
        :title="$t('message.logistics')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <div style="font-size: medium;margin: 10px">{{$t('message.DeliveryMan')}}:{{logistics.DeliveryMan}}</div>
    <div style="font-size: medium;margin: 10px">{{$t('message.DeliveryManTel')}}:{{logistics.DeliveryManTel}}</div>
    <div style="font-size: medium;margin: 10px">{{$t('message.LogisticCode')}}:{{logistics.LogisticCode}}</div>
    <van-steps direction="vertical" :active="logistics.hasOwnProperty('Traces')?logistics.Traces.length-1:0">
      <van-step v-for="(e,i) in logistics.Traces" :key="i">
        <h3>{{e.AcceptStation}}</h3>
        <p>{{e.AcceptTime}}</p>
      </van-step>


<!--      <van-step>-->
<!--        <h3>【城市】物流状态1</h3>-->
<!--        <p>2016-07-12 12:40</p>-->
<!--      </van-step>-->
<!--      <van-step>-->
<!--        <h3>【城市】物流状态2</h3>-->
<!--        <p>2016-07-11 10:00</p>-->
<!--      </van-step>-->
<!--      <van-step>-->
<!--        <h3>快件已发货</h3>-->
<!--        <p>2016-07-10 09:30</p>-->
<!--      </van-step>-->
    </van-steps>


  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "LogisticsView",
  data(){
    return {
      logistics:{},

    }
  },
  created() {
    let orderId=this.$route.query.orderId;
    axios({
      method:'post',
      url:'/order/logistics/',
      data:{
        orderId
      }
    }).then(res=>{
      this.logistics=JSON.parse(res.data.data)
    })
  }
}
</script>

<style scoped>

</style>
