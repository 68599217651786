<template>
  <div>
    <van-nav-bar
        :title="$t('message.personalSetting')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <van-grid column-num="1" center :border="false">
      <van-grid-item>
        <van-image
            round
            width="2.5rem"
            height="2.5rem"
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
        />
      </van-grid-item>
    </van-grid>

    <van-cell-group>

      <van-cell :title="$t('message.userNickname')" :value="user.userNickname" is-link @click="updateUser('昵称','userNickname')"/>
      <van-cell :title="$t('message.userSex')" :value="user.userSex" is-link @click="updateUser('性别','userSex')"/>
      <van-cell :title="$t('message.userTel')" :value="user.userTel" is-link @click="updateUser('手机号','userTel')"/>

      <van-cell :title="$t('message.userName')" :value="user.userName"/>
      <van-cell :title="$t('message.userIdcard')" :value="user.userIdcard"/>
      <van-cell :title="$t('message.userRegistTime')" :value="userRegistTime"/>

      <van-cell>
        <van-button block type="primary" size="small" @click="updateUser('密码','userPassword')">{{$t('message.updatePassword')}}</van-button>
      </van-cell>
    </van-cell-group>

    <van-dialog
        :title="'修改'+titleName"
        v-model="show"
        showCancelButton
        @confirm="confirmUpdate"
    >
      <template #default>
        <van-field v-model="input" :label="titleName+':'" :placeholder="'请输入新'+titleName"/>
      </template>
    </van-dialog>

  </div>
</template>

<script>
import axios from "axios";
import {Toast} from "vant";
// import {Dialog} from "vant";


export default {
  name: "PersonalSettingView",
  data() {
    return {
      user: {},
      show: false,
      input: '',
      titleName: '',
      fieldName: '',
    }
  },
  created() {
    axios.get('/app/user/info').then(res => {
      console.log(res.data)
      this.user = res.data.data
    })
  },
  computed: {
    userRegistTime() {
      let e = new Date(this.user.userRegistTime);
      return `${e.getFullYear()}年${e.getMonth()}月${e.getDay()}日${e.getHours()}时${e.getMinutes()}分${e.getSeconds()}秒`;
    }
  },
  methods: {
    updateUser: function (titleName, fieldName) {
      this.titleName = titleName;
      this.fieldName = fieldName;
      this.input='';
      this.show = true
    },
    confirmUpdate: function () {
      if (this.input==''){
        Toast.fail('请输入内容')
        return
      }
      if (this.titleName=='性别'){
        if (this.input!='男'&&this.input!='女'){
          Toast.fail('请输入有效的性别')
          return
        }
      }
      let user = {};
      user[this.fieldName] = this.input;
      axios.put(`/app/user/update/${this.titleName}`, user).then(res => {
        if (res.data.code == 200) {
          Toast.success(res.data.msg)
          if (this.titleName == '密码'){
            localStorage.removeItem('jwt')
            this.$store.dispatch('setLoginState',false)
            this.$router.push({path:'/login',query:{showTip:'true'}})
          }
        } else {
          Toast.fail(res.data.msg)
        }
      }).then(() => {
        axios.get('/app/user/info').then(res => {
          this.user = res.data.data
        })
      })


    }
  }
}
</script>

<style scoped>

</style>
