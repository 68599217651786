<template>

  <div>


    <van-form @submit="userLogin">
      <van-nav-bar
          :title="$t('message.login')"
          :left-text="$t('message.homepage')"
          left-arrow
          @click-left="$router.push('/index')"
      />

      <div>
        <van-image :src="require('/src/assets/bg.jpg')"></van-image>
      </div>

      <!--密码登录-->
      <div v-if="loginMode===1">
        <van-field
            left-icon="friends-o"
            v-model="userName"
            style="margin-top:10px"
            name="用户名"
            :label="$t('message.account')+':'"
            label-width="50"
            class="input"
            :rules="[{ required: true, message: $t('message.pleaseFillInTheAccount') }]"
        />
        <van-field
            left-icon="smile-comment-o"
            v-model="userPassword"
            type="password"
            name="密码"
            :label="$t('message.password')+':'"
            label-width="50"
            class="input"
            :rules="[{ required: true, message: $t('message.pleaseFillInThePassword') }]"
        />
        <div style="font-size: small;margin: 10px;text-align: right">
          <span @click="loginMode=2">{{ $t('message.telLogin') }}</span>
        </div>
      </div>

      <!--手机号登录-->
      <div v-if="loginMode===2">
        <van-field
            left-icon="phone-o"
            v-model="userTel"
            style="margin-top:10px"
            :label="$t('message.tel')+':'"
            label-width="50"
            class="input"
        />
        <van-field
            left-icon="smile-comment-o"
            v-model="verificationCode"
            name="验证码"
            :label="$t('message.code')+':'"
            label-width="50"
            class="input"
        >
          <template #button>
            <van-button size="mini" type="primary" @click.native.prevent="sendVerificationCode">{{$t('message.sendCode')}}</van-button>
          </template>
        </van-field>
        <div style="margin:10px">
        </div>
        <div style="font-size: small;margin: 10px;text-align: right">
          <span @click="loginMode=1">{{$t('message.passwordLogin')}}</span>
        </div>
      </div>

      <div style="margin: 10px;">
        <van-checkbox-group v-model="radio" direction="horizontal">
          <van-checkbox name="1" style="font-size: small">{{$t('message.iHaveReadAndUnderstoodTheTermsAndConditions')}}</van-checkbox>
        </van-checkbox-group>
      </div>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">{{ $t('message.login') }}</van-button>
      </div>

      <div style="margin: 10px;font-size: small;text-align: center">
        <div>{{ $t('message.noAccountYet') }}?<span style="color: #42b983" @click="$router.push('/regist')">{{ $t('message.regist') }}</span>{{ $t('message.one') }}</div>
      </div>
    </van-form>


  </div>

</template>

<script>

import axios from "axios";
import {Toast} from "vant";

export default {
  name: "LoginView",
  data() {
    return {
      userName: '张三',
      userPassword: '123456',
      // userName: '',
      // userPassword: '',
      userTel: '',
      verificationCode: '',
      radio: ['1'],
      //1:密码登录 2:手机号登录
      loginMode: 1,
      captcha: ``,
      verifyCodeUrl: `${axios.defaults.baseURL}/AppModule/captcha?${Date.now()}`,
    }
  },
  methods: {
    userLogin: function () {
      if (this.loginMode == 1) {
        // console.log('用户名登录')
        if (this.radio.length === 0) {
          Toast.fail(this.$t('message.pleaseAgreeTheTermsAndConditionsFisrt'))
          return
        }
        axios({
          method: 'POST',
          url: '/app/user/userName/login',
          data: {
            userName: this.userName,
            userPassword: this.userPassword,
            captcha: this.captcha,
          },
        }).then(res => {
          console.log(res.data)
          if (res.data.code == 250) {
            Toast.fail(res.data.msg)
          } else {
            window.localStorage.setItem('jwt', res.data.data.toString())
            Toast.success(this.$t('message.loginSuccess'))
            this.$store.dispatch('setLoginState', true)
            this.$router.push('/index')
          }
        })
      } else {
        if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.userTel)) {
          Toast.fail(this.$t('message.pleaseEnterTheCorrectTel'))
          return
        }
        if (this.verificationCode.length !== 4) {
          Toast.fail(this.$t('message.pleaseEnterValidCode'))
          return
        }
        // console.log('手机号登录');
        axios({
          method: 'POST',
          url: `/app/user/userTel/login/${this.userTel}/${this.verificationCode}`,
        }).then(res => {
          console.log(res.data)
          if (res.data.code == 250) {
            Toast.fail(res.data.msg)
          } else {
            window.localStorage.setItem('jwt', res.data.data.toString())
            Toast.success(this.$t('message.loginSuccess'))
            this.$store.dispatch('setLoginState', true)
            this.$router.push('/index')
          }
        })
      }
    },
    sendVerificationCode: function () {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.userTel)) {
        Toast.fail(this.$t('message.pleaseEnterTheCorrectTel'))
        return
      }
      console.log('发送手机验证码')
      axios.get(`/app/user/telCode/${this.userTel}`).then(res => {
        if (res.data.code == 200) {
          Toast.success(res.data.msg)
        }
      })
    },

  },
  created() {
    if (this.$route.query.showTip == 'true') {
      Toast.fail(this.$t('message.loginAgain'))
    }
  },
  watch: {}
}
</script>

<style scoped>


</style>
