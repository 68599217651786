import { render, staticRenderFns } from "./AddressView.vue?vue&type=template&id=af74a088&scoped=true&className=hideButton&"
import script from "./AddressView.vue?vue&type=script&lang=js&"
export * from "./AddressView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af74a088",
  null
  
)

export default component.exports