<template>

  <van-form @submit="userRegist">

    <van-nav-bar
        :title="$t('message.regist')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.push('/login')"
    />

    <div style="text-align: center">
      <van-uploader v-model="fileList" multiple :max-count="1" :after-read="afterRead"/>
    </div>

    <van-field
        left-icon="friends-o"
        v-model="userName"
        style="margin-top:10px"
        :label="$t('message.account')+':'"
        label-width="50"
        class="input"

        :rules="[{ required: true, message: $t('message.fillUserName') }]"
    />

    <van-field
        left-icon="contact"
        v-model="userNickname"
        style="margin-top:10px"
        :label="$t('message.userNickname')+':'"
        label-width="50"
        class="input"
        :rules="[{ required: true, message: $t('message.fillUsernickname') }]"
    />

    <van-field
        left-icon="smile-comment-o"
        v-model="userPassword"
        style="margin-top:10px"
        type="password"
        :label="$t('message.userPassword')+':'"
        label-width="50"
        class="input"

        :rules="[{ required: true, message: $t('message.filluserPassword') }]"
    />

    <van-field
        left-icon="smile-comment-o"
        v-model="confirmUserPasswrd"
        style="margin-top:10px"
        type="password"
        :label="$t('message.confirmPassword')+':'"
        label-width="65"
        class="input"

        :rules="[{ required: true, message: $t('message.filluserPassword') }]"
    />

    <van-field
        left-icon="phone-o"
        v-model="userTel"
        style="margin-top:10px"
        :label="$t('message.userTel')+':'"
        label-width="50"
        class="input"

        :rules="[{ required: true, message: $t('message.filluserTel') }]"
    />

    <van-field
        left-icon="idcard"
        v-model="userIdcard"
        style="margin-top:10px"
        :label="$t('message.userIdcard')+':'"
        label-width="50"
        class="input"

        :rules="[{ required: true, message: $t('message.filluserIdcard') }]"
    />


    <van-radio-group v-model="sexRadio">
      <van-cell-group>
        <van-cell :title="$t('message.male')" clickable>
          <template #right-icon>
            <van-radio name="男" />
          </template>
        </van-cell>
        <van-cell :title="$t('message.female')" clickable>
          <template #right-icon>
            <van-radio name="女" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <div style="margin: 10px;">
      <van-checkbox-group v-model="radio" direction="horizontal">
        <van-checkbox name="1" style="font-size: small">{{$t('message.iHaveReadAndUnderstoodTheTermsAndConditions')}}</van-checkbox>
      </van-checkbox-group>
    </div>

    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit">{{$t('message.regist')}}</van-button>
    </div>


    <div style="margin: 10px;font-size: small;text-align: center">
      <div>{{$t('message.alreadyHasAnAccount')}}<span style="color: #42b983" @click="$router.push('/login')">{{$t('message.login')}}</span></div>
    </div>



  </van-form>

</template>

<script>
import {Toast} from "vant";
import axios from "axios";

export default {
  name: "RegistView",
  data(){
    return{
      //是否勾选同意
      radio:['1'],
      //性别选择
      sexRadio:'男',
      //注册
      // userName:'张三',
      // userNickname:'哈哈哈',
      // userPassword:'12345',
      // confirmUserPasswrd:'12345',
      // userTel:'17605002329',
      // userIdcard:'350181199907071814',
      userHeadimgUrl:'',
      userName:'',
      userNickname:'',
      userPassword:'',
      confirmUserPasswrd:'',
      userTel:'',
      userIdcard:'',


      //验证码
      verifyCode:'',

      fileList:[],
    }
  },
  watch:{
    sexRadio:function () {
      console.log(arguments[0])
    },
    fileList:function () {
      // console.log(arguments[0])
    },
  },
  methods:{
    afterRead:function (file) {
      axios({
        method:'post',
        url:'/img/upload',
        headers:{
          'content-type':'multipart/form-data'
        },
        data:{
          file:file.file
        },
      }).then(res => {
        console.log(res.data)
        this.userHeadimgUrl=res.data
      });
    },
    userRegist:function () {
      //判空
      if (this.userName==''||this.userNickname==''||this.userPassword==''||this.confirmUserPasswrd==''||this.userTel==''||this.userIdcard==''){
        Toast.fail(this.$t('message.pleaseFillInTheCompleteInformation'))
        return
      }

      if (this.fileList.length==0){
        Toast.fail(this.$t('message.pleaseUploadYourAvatar'))
        return
      }

      //是否勾选同意协议
      if (this.radio.length==0){
        Toast.fail(this.$t('message.pleaseAgreeTheTermsAndConditionsFisrt'))
        return
      }

      //限制账号长度
      if (this.userName.length>10){
        Toast.fail(this.$t('message.theAccountLengthCannotBeGreaterThan10'))
        return
      }

      //限制账号昵称长度
      if (this.userNickname.length>10){
        Toast.fail(this.$t('message.theNicknameLengthCannotBeGreaterThan10'))
        return
      }

      //限制账号密码长度
      if (this.userPassword.length>10){
        Toast.fail(this.$t('message.thePasswordLengthCannotBeGreaterThan10'))
        return
      }

      //密码上下是否一致
      if (this.userPassword!=this.confirmUserPasswrd){
        Toast.fail(this.$t('message.inconsistentPasswordInput'))
        return
      }

      if (this.userTel.length!=11){
        Toast.fail(this.$t('message.pleaseEnterTheCorrectTel'))
        return
      }

      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.userTel)){
        Toast.fail(this.$t('message.pleaseEnterTheCorrectTel'))
        return
      }

      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.userIdcard)){
        Toast.fail(this.$t('message.pleaseEnterTheCorrectIdcard'))
        return
      }

      if (this.userHeadimgUrl==''){
        return
      }

      axios({
        method:'post',
        url:'/app/user/userName/regist',
        data:{
          userName:this.userName,
          userNickname:this.userNickname,
          userPassword:this.userPassword,
          userTel:this.userTel,
          userIdcard:this.userIdcard,
          userSex:this.sexRadio,
          userHeadimgUrl:this.userHeadimgUrl,
        }
      }).then(res=>{
        if (res.data.code==250){
          Toast.fail(res.data.msg)
        }else {
          Toast.success(res.data.msg)
        }
      })

      console.log('用户注册')
    },

    getCaptcha:function () {
      console.log('获取验证码')


    }


  },
  created() {
    console.log('获取验证码')
  }
}
</script>

<style scoped>

</style>
