<template>
  <div class="home">

    <van-nav-bar
        :title="$t('message.activity')"
        :left-text="$t('message.homepage')"
        left-arrow
        @click-left="$router.push('/index')"
    />

    <van-cell>
      <van-tabs v-model="active">
        <van-tab v-for="(e,i) in activities" :title="e.contentContent" :key="i">
          <van-count-down :time="new Date(e.contentEndtime).getTime()-Date.now()" format="DD天HH时mm分ss秒">
            <template #default="timeData">
              <br>
              <!--              <span class="colon">距结束</span>-->
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">{{ $t('message.days') }}</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">{{ $t('message.hours') }}</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">{{ $t('message.minutes') }}</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">{{ $t('message.seconds') }}</span>
            </template>
          </van-count-down>
          <br>

          <van-card
              v-for="(e,i) in activityGoodList"
              :title="e.goodName"
              :thumb="baseURL+'/img/download/'+e.imgUrl"
              :origin-price="e.goodPrice"
              :num="e.goodStock"
              :key="i">
            <template #footer>
              <van-button style="text-align: right" type="primary" size="small"
                          @click="$router.push({path:'/goodDetail', query:{type:activities[active].contentId==1?'seckill':'activity',activityId:e.activityId,goodId:e.goodId,goodDetailId:e.goodDetailId,contentId:activities[active].contentId}})">
                {{ activities[active].contentId == 1 ? $t('message.seckill') : $t('message.buy') }}
              </van-button>
            </template>
          </van-card>
        </van-tab>
      </van-tabs>
    </van-cell>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ActivityView",
  components: {},
  data() {
    return {
      active: null,
      time: 30 * 60 * 60 * 1000,
      activities: [],
      //秒杀活动商品列表
      activityGoodList: [],
      baseURL: axios.defaults.baseURL,
    }
  },
  watch: {
    active: function (nv) {
      // console.log(nv)
      axios.get(`/app/activity/selectActivityGood/${this.activities[nv].contentId}/1/10`).then(res => {
        // console.log(res.data)
        this.activityGoodList = res.data.data
      })
    }
  },
  created() {
    //查询所有活动
    axios.get(`/app/activity/selectAll`).then(res => {
      // console.log(res.data.data)
      this.activities = res.data.data
    })
  }
}
</script>

<style scoped>
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #000000;
}

.block {
  display: inline-block;
  width: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #000000;
  border-radius: 5px;
}
</style>
