<template>

  <div>


    <van-nav-bar :title="$t('message.personalCenter')"/>

    <van-grid direction="horizontal" :column-num="2" :border="false">
      <van-grid-item icon="photo-o" text="文字">
        <van-image
            width="60"
            height="60"
            :src="baseURL+'/img/download/'+user.userHeadimgUrl"
            :round="true"
        />
      </van-grid-item>
      <van-grid-item>
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" v-if="!$store.getters.getLoginState"
                    @click="$router.push('/login')">
          {{$t('message.logInNow')}}

        </van-button>
        <van-button type="warning" v-if="$store.getters.getLoginState" @click="exitLogin">{{$t('message.exitLogin')}}</van-button>
      </van-grid-item>
    </van-grid>

    <van-cell :title="$t('message.myOrder')" :border="false"/>

    <van-grid column-num="5">
      <van-grid-item icon="pending-payment" :text="$t('message.obligation')" @click="$router.push({path:'/obligation'})"/>
      <van-grid-item icon="paid" :text="$t('message.paid')" @click="$router.push({path:'/paid'})"/>
      <van-grid-item icon="logistics" :text="$t('message.waiting')" @click="$router.push({path:'/waiting'})"/>
<!--      <van-grid-item icon="comment-o" text="待评价"/>-->
<!--      <van-grid-item icon="service-o" text="退款/售后"/>-->
      <van-grid-item icon="" text=""/>
      <van-grid-item icon="" text=""/>
    </van-grid>

    <van-cell :title="$t('message.myService')" :border="false"/>

    <van-grid column-num="5">
      <van-grid-item icon="setting-o" :text="$t('message.personalCenter')" @click="$router.push('/personalSetting')"/>
      <van-grid-item icon="location-o" :text="$t('message.shippingAddress')" @click="$router.push('/address')"/>
      <van-grid-item icon="coupon-o" :text="$t('message.coupon')" @click="$router.push('/coupons')"/>
      <van-grid-item icon="notes-o" :text="$t('message.changeLanguage')" @click="changeLanguage"/>
      <van-grid-item icon="" text=""/>
    </van-grid>


  </div>


</template>

<script>
import {Dialog, Locale} from "vant";
import axios from "axios";
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
export default {
  name: "MineView",
  data() {
    return {
      baseURL: axios.defaults.baseURL,
      user: {},
    }
  },
  methods: {
    exitLogin: function () {
      Dialog.confirm({
        title: this.$t('message.tip'),
        message: this.$t('message.confirmExitLogin'),
      }).then(() => {
        window.localStorage.removeItem('jwt')
        this.$store.dispatch('setLoginState', false)
      }).catch(() => {
      });
    },
    changeLanguage: function () {
      if (this.$i18n.locale === 'zh') {
        localStorage.setItem('language','en')
        this.$i18n.locale = 'en'
        Locale.use('en-US', enUS);
      } else {
        localStorage.setItem('language','zh')
        this.$i18n.locale = 'zh'
        Locale.use('zh-CN', zhCN);
      }
    }
  },
  created() {
    axios.get('/app/user/info').then(res => {
      console.log(res.data)
      this.user = res.data.data
    })
  }
}
</script>

<style scoped>

</style>
