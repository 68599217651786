<template>
<div>
  <van-nav-bar
      :title="operation==='addAddress'?$t('message.addAddress'):$t('message.editAddress')"
      :left-text="$t('message.back')"
      left-arrow
      @click-left="$router.back()"
  />
  <van-address-edit
      :address-info="addressInfo"
      :area-list="areaList"
      show-postal
      :show-delete="operation!=='addAddress'"
      show-set-default
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
  />

<!--  <van-button @click="test">测试</van-button>-->

</div>
</template>

<script>
import {Toast} from "vant";
import { areaList } from '@vant/area-data';
import axios from "axios";

export default {
  name: "AddressDetailView",
  data() {
    return {
      // areaList:{},
      areaList,
      searchResult: [],

      //标题
      operation:'addAddress',

      //仅仅作为初始值传入
      addressInfo:{
        id: '',
        name: '',
        tel: '',
        address: '',
        isDefault: false,
      },

    };
  },
  methods: {
    onSave(addressObj) {
      // console.log(addressObj)
      if (this.operation=='addAddress'){
        axios({
          method:'POST',
          url:'/app/address/addAddress',
          data:{
            addressProvince:addressObj.province,
            addressCity:addressObj.city,
            addressCounty:addressObj.county,
            addressDetail:addressObj.addressDetail,
            addressName:addressObj.name,
            addressTel:addressObj.tel,
            addressPostalCode:addressObj.postalCode,
            addressAreaCode:addressObj.areaCode,
            isDefault:addressObj.isDefault,
          }
        }).then(res=>{
          console.log(res.data)
          if (res.data.code==250){
            Toast.fail(res.data.msg)
          }else{
            Toast.success(res.data.msg)
          }
        })
      }else if (this.operation=='editAddress'){
        console.log('编辑并保存地址');
        console.log(addressObj)
        axios({
          method:'PUT',
          url:'/app/address/editAddress',
          data:{
            addressId:addressObj.id,
            addressProvince:addressObj.province,
            addressCity:addressObj.city,
            addressCounty:addressObj.county,
            addressDetail:addressObj.addressDetail,
            addressName:addressObj.name,
            addressTel:addressObj.tel,
            addressPostalCode:addressObj.postalCode,
            addressAreaCode:addressObj.areaCode,
            isDefault:addressObj.isDefault,
          }
        }).then(res=>{
            Toast.success(res.data.msg);
            this.$router.back();
        })
      }

    },
    onDelete() {
      // Toast('delete');
      console.log('删除'+this.addressInfo.id)
      axios({
        method:'delete',
        url:'/app/address/deleteAddress',
        data:{
          addressId:this.addressInfo.id,
        }
      }).then(res=>{
        Toast.success(res.data.msg);
        this.$router.back();
      })
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区',
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
    test:function () {
      this.addressInfo.name='林耀光'
      console.log(JSON.stringify(this.addressInfo))
    },
  },
  computed:{

  },
  watch:{
    // addressInfo:function (nv) {
    //   console.log(nv)
    // }
  },
  created() {
    this.operation=this.$route.query.operation
    if (this.operation=='editAddress'){
     let e= JSON.parse(this.$route.query.address).origin;
      this.addressInfo={
        id:e.addressId,
        name:e.addressName,
        tel:e.addressTel,
        province:e.addressProvince,
        city:e.addressCity,
        county:e.addressCounty,
        addressDetail:e.addressDetail,
        areaCode:e.addressAreaCode,
        postalCode:e.addressPostalCode,
        isDefault: JSON.parse(this.$route.query.address).isDefault,
      }
      // console.log(this.addressInfo)
    }

  }
}
</script>

<style scoped>

</style>
