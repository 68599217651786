<template>
  <div>

    <van-nav-bar
        :title="$t('message.paid')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
    <div v-for="(e,i) in paid" :key="i">
      <van-cell>{{ $t('message.orderId') }}:{{e[0].orderId}}</van-cell>
      <van-grid column-num="2" :border="false" v-for="(e1,i1) in e" :key="i1">
        <van-grid-item>
          <van-image :src="baseURL+'/img/download/'+e1.imgUrl" width="100px"></van-image>
        </van-grid-item>
        <van-grid-item>x {{e1.goodAmount}}</van-grid-item>
      </van-grid>
      <div style="text-align: right">
        <van-button round type="info" size="small" @click="$router.push({path:'/orderDetail',query:{orderId:e[0].orderId}})">{{ $t('message.orderDetail') }}</van-button>
      </div>
    </div>
    </van-list>

    <van-empty description="这里空空如也" v-if="paid.length===0"/>


  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "PaidView",
  data() {
    return {
      paid:[],
      loading:true,
      finished:true,
      pageNum:1,
      baseURL:axios.defaults.baseURL,
    }
  },
  methods: {
    onLoad:function () {
      console.log('触底加载')
      axios({
        method:'get',
        url:`/order/order/${++this.pageNum}/10/1`
      }).then(res=>{
        console.log(res.data.data.length)
        for (let i = res.data.data.length - 1; i >= 0; i--) {
          if (res.data.data[i].length==0){
            res.data.data.splice(i,1);
          }
        }
        this.loading=false
        if (res.data.data.length!==10){
          this.finished=true
        }
      })
    },
  },
  created() {

    axios({
      method:'get',
      url:`/order/order/${this.pageNum}/10/1`,
    }).then(res=>{
      for (let i = res.data.data.length - 1; i >= 0; i--) {
        if (res.data.data[i].length===0){
          res.data.data.splice(i,1);
        }
      }
      this.paid=res.data.data
      this.finished=false
      this.loading=false
    })
  }
}
</script>

<style scoped>

</style>
