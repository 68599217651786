<template>
  <div>
    <van-search
        v-model="searchContent"
        :placeholder="$t('message.pleaseEnterSearchKeywords')"
        input-align="center"
        @search="search"
    />
    <van-tabs  v-model="tabsIndex">
      <van-tab v-for="(e,i) in tabs" :title="e.name" :key="i" style="background-color: #42b983"></van-tab>
    </van-tabs>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" show-indicators>
      <van-swipe-item @click="$router.push('/activity')">
        <van-image :src="require('../assets/flashSalesPromotion.jpg')" style="width: auto"></van-image>
      </van-swipe-item>
<!--      <van-swipe-item>-->
<!--        <van-image src="https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"></van-image>-->
<!--      </van-swipe-item>-->
<!--      <van-swipe-item>-->
<!--        <van-image src="https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"></van-image>-->
<!--      </van-swipe-item>-->
<!--      <van-swipe-item>-->
<!--        <van-image src="https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"></van-image>-->
<!--      </van-swipe-item>-->
    </van-swipe>
    <van-list
        :v-model="loading"
        :finished="finished"
        :finished-text="$t('message.noMore')"
        @load="onLoad"
    >
      <van-grid square column-num="2" :border="false">
        <van-grid-item v-for="(e,i) in list" :key="i">
          <van-image :src="e.imgUrl" width="70%" height="70%" @click="$router.push({path:'/goodDetail',query:{type:'normal',goodId:e.goodId}})"></van-image>
          <div style="font-size: small">{{ e.goodName }}</div>
        </van-grid-item>
      </van-grid>
    </van-list>
  </div>
</template>
<script>
//import {Toast} from "vant";
import axios from "axios";
import {Toast} from "vant";
export default {
  name: "IndexView",
  data() {
    return {
      searchContent: '',
      tabs: [
        {
          kindId:-1,
          name: '热卖'
        },
      ],

      tabsIndex:0,

      loading: false,
      finished: true,
      list: [],
      pageNum: 1,
    }
  },
  methods: {
    search: function () {
      console.log('搜索内容')
      if (this.searchContent==''){
        Toast.fail(this.$t('message.pleaseEnterSearchContent'))
        return
      }
      this.$router.push({path:'/search',query:{searchContent:this.searchContent}})
    },
    onLoad() {
      ++this.pageNum
      axios({
        method: 'GET',
        url: `/app/good/selectGoodVo/${this.pageNum}/10`,
      }).then(res => {
        console.log('触底加载'+res.data.data.length)
        for (let e of res.data.data) {
          e.imgUrl=`${axios.defaults.baseURL}/img/download/${e.imgUrl}`
          // e.imgUrl = "https://s2.loli.net/2023/04/05/9eba7xqhNRKy51C.jpg"
        }
        //不将重复数据加进去
        for (let e of this.list) {
          for (let v of res.data.data) {
            if (e.goodId==v.goodId){
              continue
            }else if (v.goodName=='会员'){
              continue
            } else{
              this.list.push(v)
            }
          }
        }
        // this.list=this.list.concat(res.data.data)
        if (res.data.data.length!=10){
          this.finished = true
        }
      })
    },
    jumpTo(path) {
      this.$router.push(path)
    },
    test: function () {
      this.$store.dispatch('setLoginState', true)
    }
  },
  watch:{
    tabsIndex:function (nv) {
      if (nv==0){
        // console.log('热卖')
        axios({
          method: 'GET',
          url: `/app/good/selectGoodVo/${this.pageNum}/10`,
        }).then(res => {
          for (let e of res.data.data) {
            e.imgUrl=`${axios.defaults.baseURL}/img/download/${e.imgUrl}`
          }
          this.list=res.data.data
        })
      }else{
        // console.log('类型')
        let kindId=this.tabs[nv].kindId;
        axios({
          method: 'GET',
          url: `/app/good/selectGoodVo/${this.pageNum}/10/${kindId}`,
        }).then(res => {
          for (let e of res.data.data) {
            e.imgUrl=`${axios.defaults.baseURL}/img/download/${e.imgUrl}`
          }
          this.list=res.data.data
        })
      }
    }
  },
  created() {
    //console.log('首页 created')
    axios({
      method: 'GET',
      url: `/app/good/selectGoodVo/${this.pageNum}/10`,
    }).then(res => {
      console.log('初始化首页数据')
      // console.log(res.data)
      for (let e of res.data.data) {
        e.imgUrl=`${axios.defaults.baseURL}/img/download/${e.imgUrl}`
      }
      this.list=res.data.data
    }).then(()=>{

    })

    //查询所有分类
    axios({
      method:'get',
      url:'/app/kind/kinds'
    }).then(res=>{
      console.log('查询分类')
      for (let e of res.data.data) {
        this.tabs.push({
          kindId:e.kindId,
          name:e.kindName,
        })
      }
    })
  }
}
</script>

<style scoped>

</style>
