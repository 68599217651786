import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant';
import 'vant/lib/index.css';
// import 'amfe-flexible'

import axios from "axios";

Vue.use(Vant);
Vue.config.productionTip = false
axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'http://121.37.227.73:8084'
axios.defaults.baseURL= "https://6014119ax7.goho.co/fazegateway";

//本地开发
// axios.defaults.baseURL='http://localhost:8084'
// 请求拦截器
axios.interceptors.request.use(
    request => {
        if (localStorage.getItem('jwt')) {
            request.headers.jwt = localStorage.getItem('jwt');
        }
        return request;
    },
)
//响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code == '401') {
            vm.$router.push({
                path: '/login',
                query: {
                    showTip: 'true'
                },
            })
            return Promise.reject('jwt认证失败')
        }
        return response;
    },
);
Vue.prototype.$axios = axios

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 准备翻译的语言环境信息
const messages = {
    en: {
        message: {
            index: 'Home',
            message: 'Message',
            shopcar: 'Cart',
            vip: 'Vip',
            mine: 'Mine',
            personalCenter: 'Personal\nCenter',
            personalSetting: 'PersonalSetting',
            shippingAddress: 'Shopping \nAddress',
            coupon: 'Coupon',
            changeLanguage: 'ChangeLanguage',
            myService: 'Service',
            myOrder: 'Order',
            obligation: 'Obligation',
            paid: 'Paid',
            waiting: 'Waiting',
            exitLogin: 'ExitLogin',
            logInNow: 'Log In Now',
            activity: 'Activity',
            homepage: 'Homepage',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
            seconds: 'seconds',
            seckill: 'seckill',
            buy: 'buy',
            addAddress: 'addAddress',
            editAddress: 'editAddress',
            back: 'Back',
            address: 'address',
            confirmOrder: 'ConfirmOrder',
            updateDefaultAddress: 'UpdateDefaultAddress',
            orderPrice: 'OrderPrice',
            discountPrice: 'DiscountPrice',
            claimCoupons: 'ClaimCoupons',
            expirationTime:'ExpirationTime',
            couponLimit:'CouponLimit',
            collected:'Collected',
            collect:'Collect',
            myCoupons:'MyCoupons',
            usedExpired:'Used / Expired',
            goodDetail:'GoodDetail',
            goodView:'View',
            goodName:'Name',
            brandName:'Brand',
            goodDesc:'Description',
            chat:'Chat',
            stared:'Stared',
            star:'Star',
            addToGoodcar:'AddToGoodcar',
            property:'property',
            specs:'specs',
            stock:'Stock',
            amount:'Amount',
            price:'Price',
            totalPrice:'TotalPrice',
            confirm:'Confirm',
            buyNow:'BUY NOW',
            notEnoughStock:'Not enough stock',
            pleaseEnterSearchKeywords:'Please enter search keywords',
            noMore:'No More',
            pleaseEnterSearchContent:'Please enter search content',
            login:'Login',
            account:'Account',
            password:'Password',
            pleaseFillInTheAccount:'Please Fill In The Account',
            pleaseFillInThePassword:'Please Fill In The Password',
            telLogin:'Tel Login',
            tel:'Tel',
            code:'Code',
            sendCode:'SendCode',
            passwordLogin:'PasswordLogin',
            iHaveReadAndUnderstoodTheTermsAndConditions:'I have read and understood the terms and conditions',
            regist:'Regist',
            one:'one',
            noAccountYet:'No account yet',
            pleaseAgreeTheTermsAndConditionsFisrt:'Please agree the terms and conditions fisrt',
            loginSuccess:'Login Success',
            pleaseEnterTheCorrectTel:'Please enter a valid tel',
            pleaseEnterValidCode:'Please enter a valid verification code',
            loginAgain:'Login status is invalid or expired, please log in again',
            tip:'Tip',
            confirmExitLogin:'Confirm Exit Login',
            orderId:'Order Number',
            cancelOrder:'CancelOrder',
            orderDetail:'OrderDetail',
            payOrder:'PayOrder',
            confirmCancelOrder:'Are you sure to cancel the order?',
            paymentAmount:'PaymentAmount',
            alipay:'AliPay',
            weChat:'WeChat',
            confirmPay:'ConfirmPay',
            weChatPaymentIsCurrentlyUnavailable:'WeChat payment is currently unavailable',
            userNickname:'Nickname',
            userSex:'Sex',
            userTel:'Tel',
            userName:'Account',
            userIdcard:'Idcard',
            userRegistTime:'RegistTime',
            updatePassword:'UpdatePassword',
            fillUserName:'Please fill in the username',
            fillUsernickname:'Please fill in the nickname',
            userPassword:'Password',
            filluserPassword:'Please fill in the password',
            confirmPassword:'ConfirmPassword',
            filluserTel:'Please fill in the tel',
            male:'male',
            female:'female',
            alreadyHasAnAccount:'Already has an account?',
            pleaseFillInTheCompleteInformation:'Please fill in the complete information',
            pleaseUploadYourAvatar:'Please upload your avatar',
            theAccountLengthCannotBeGreaterThan10:'The account length cannot be greater than 10',
            theNicknameLengthCannotBeGreaterThan10:'The nickname length cannot be greater than 10',
            thePasswordLengthCannotBeGreaterThan10:'The password length cannot be greater than 10',
            inconsistentPasswordInput:'Inconsistent password input',
            pleaseEnterTheCorrectIdcard:'Please enter the correct idcard',
            searchResult:'SearchResult',
            monthly:'Monthly',
            quarter:'Quarter',
            yearly:'Yearly',
            vipLevel:'Level',
            membershipValidUntil:'Membership valid until',
            renewVip:'RenewVip',
            openVip:'OpenVip',
            createdTime:'CreatedTime',
            state:'State',
            pay:'Pay',
            deleteGood:'DeleteGood',
            viewLogistics:'ViewLogistics',
            logistics:'Logistics',
            DeliveryMan:'DeliveryMan',
            DeliveryManTel:'DeliveryManTel',
            LogisticCode:'LogisticCode',
        }
    },
    zh: {
        message: {
            index: '首页',
            message: '消息',
            shopcar: '购物车',
            vip: '会员',
            mine: '我的',
            personalCenter: '个人中心',
            personalSetting: '个人设置',
            shippingAddress: '收货地址',
            coupon: '优惠券',
            changeLanguage: '切换语言',
            myService: '我的服务',
            myOrder: '我的订单',
            obligation: '待付款',
            paid: '待发货',
            waiting: '待收货',
            exitLogin: '退出登录',
            logInNow: '立即登录',
            activity: '活动',
            homepage: '返回首页',
            days: '天',
            hours: '时',
            minutes: '分',
            seconds: '秒',
            seckill: '秒杀',
            buy: '购买',
            addAddress: '添加地址',
            editAddress: '编辑地址',
            back: '返回',
            address: '地址',
            confirmOrder: '确认订单',
            updateDefaultAddress: '修改默认收货地址',
            orderPrice: '订单价格',
            discountPrice: '优惠价格',
            claimCoupons: '领取优惠券',
            expirationTime:'过期时间',
            couponLimit:'优惠门槛',
            collected:'已领用',
            collect:'领用',
            myCoupons:'我的优惠券',
            usedExpired:'已使用 / 过期',
            goodDetail:'商品详情',
            goodView:'浏览',
            goodName:'名称',
            brandName:'品牌',
            goodDesc:'描述',
            chat:'客服',
            stared:'已收藏',
            star:'收藏',
            addToGoodcar:'加入购物车',
            property:'属性',
            specs:'规格',
            stock:'库存',
            amount:'数量',
            price:'价格',
            totalPrice:'总价',
            confirm:'确定',
            buyNow:'立即购买',
            notEnoughStock:'库存不足',
            pleaseEnterSearchKeywords:'请输入搜索关键词',
            noMore:'没有更多了',
            pleaseEnterSearchContent:'请输入搜索内容',
            login:'登录',
            account:'账号',
            password:'密码',
            pleaseFillInTheAccount:'请填写账号',
            pleaseFillInThePassword:'请填写密码',
            telLogin:'手机号登录',
            tel:'手机号',
            code:'验证码',
            sendCode:'发送验证码',
            passwordLogin:'密码登录',
            iHaveReadAndUnderstoodTheTermsAndConditions:'我已阅读并同意相关政策',
            regist:'注册',
            one:'一个',
            noAccountYet:'还没有账号',
            pleaseAgreeTheTermsAndConditionsFisrt:'请先同意政策条款',
            loginSuccess:'登录成功',
            pleaseEnterTheCorrectTel:'请输入正确的手机号码',
            pleaseEnterValidCode:'请输入有效的验证码',
            loginAgain:'登录状态失效或过期,请重新登录',
            tip:'提示',
            confirmExitLogin:'确定要退出登录吗',
            orderId:'订单号',
            cancelOrder:'取消订单',
            orderDetail:'订单详情',
            payOrder:'支付订单',
            confirmCancelOrder:'确定要取消订单吗?',
            paymentAmount:'支付金额',
            alipay:'支付宝',
            weChat:'微信',
            confirmPay:'确认支付',
            weChatPaymentIsCurrentlyUnavailable:'微信支付暂不可用',
            userNickname:'昵称',
            userSex:'性别',
            userTel:'手机号',
            userName:'账号',
            userIdcard:'身份证',
            userRegistTime:'注册时间',
            fillUserName:'请填写用户名',
            fillUsernickname:'请填写昵称',
            userPassword:'密码',
            filluserPassword:'请填写密码',
            confirmPassword:'确认密码',
            filluserTel:'请填写手机号',
            filluserIdcard:'请填写身份证',
            male:'男',
            female:'女',
            alreadyHasAnAccount:'已经有账号?',
            pleaseFillInTheCompleteInformation:'请填写完整信息',
            pleaseUploadYourAvatar:'请上传头像',
            theAccountLengthCannotBeGreaterThan10:'账号长度不能大于10',
            theNicknameLengthCannotBeGreaterThan10:'账号昵称不能大于10',
            thePasswordLengthCannotBeGreaterThan10:'账号密码不能大于10',
            inconsistentPasswordInput:'密码上下输入不一致',
            pleaseEnterTheCorrectIdcard:'请输入正确的身份证号码',
            searchResult:'搜索结果',
            monthly:'月度',
            quarter:'季度',
            yearly:'年度',
            vipLevel:'您当前的会员等级',
            membershipValidUntil:'会员有效期至',
            renewVip:'续费会员',
            openVip:'开通会员',
            createdTime:'创建时间',
            state:'状态',
            pay:'支付',
            deleteGood:'删除商品',
            viewLogistics:'查看物流',
            logistics:'物流',
            DeliveryMan:'快递员',
            DeliveryManTel:'快递员电话',
            LogisticCode:'物流单号',
            updatePassword:'更新密码',
        }
    }
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    locale: 'zh', // 设置地区
    messages, // 设置地区信息
})



let vm = new Vue({
    router,
    store, i18n,
    render: h => h(App)
}).$mount('#app')
