<template>
  <div>
    <van-nav-bar
        :title="$t('message.orderDetail')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />
<!--    <van-cell v-if="isShowOrderCreateTime">订单创建时间:2023年4月12日09:43:32</van-cell>-->
<!--    <van-cell v-if="isShowOrderConfirmTime">订单确认时间:2023年4月12日09:43:33</van-cell>-->
<!--    <van-cell v-if="isShowPayTime">订单支付时间:2023年4月12日09:43:33</van-cell>-->
<!--    <van-cell v-if="isShowOrderLogistics">订单物流号:123456789</van-cell>-->
<!--    <van-cell v-if="isShowPayType">订单支付方式:微信</van-cell>-->
<!--    <van-cell v-if="isShowOrderState">订单状态:等待买家付款</van-cell>-->
<!--    <van-cell v-if="isShowPayState">支付状态:等待买家付款</van-cell>-->
<!--    订单状态 0待付款 1已付款 2已取消 3已发货 4待收货 5已收货 6退货-->
    <van-cell-group>
      <van-cell :label="defaultAddressObj.addressProvince+' '+defaultAddressObj.addressCity+' '+defaultAddressObj.addressCounty+' '+defaultAddressObj.addressDetail" >
        <template #title>
          <span>{{defaultAddressObj.addressName}}</span>&nbsp;
          <span>{{defaultAddressObj.addressTel}}</span>
        </template>
        <template #right-icon>
          <van-button size="small" type="primary" @click="$router.push({path:'/Address',query:{from:'orderDetail'}})">{{$t('message.updateDefaultAddress')}}</van-button>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell v-if="orderDetailObj.orderCreateTime!=null">{{$t('message.createdTime')}}:{{orderDetailObj.orderCreateTime}}</van-cell>
    <van-cell v-if="orderDetailObj.orderState!=null">{{$t('message.state')}}:{{orderStateMap[orderDetailObj.orderState]}}</van-cell>

<!--    <van-cell>运费:¥0</van-cell>-->

    <!-- 优惠券单元格 -->
<!--    <van-coupon-cell-->
<!--        :coupons="coupons"-->
<!--        :chosen-coupon="chosenCoupon"-->
<!--        @click="showList = true"-->
<!--    />-->
    <!-- 优惠券列表 -->
    <van-popup
        v-model="showList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px;"
    >
      <van-coupon-list
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          :disabled-coupons="disabledCoupons"
          @change="onChange"
          @exchange="onExchange"
          v-if="true"
      />
    </van-popup>

<!--    <van-grid column-num="2" :border="false" v-for="e in goodList" :key="e.goodId">-->
<!--        <van-grid-item >-->
<!--          <van-image :src="e.imgUrl"></van-image>-->
<!--        </van-grid-item>-->
<!--        <van-grid-item><p>x {{e.buyAmount}}</p></van-grid-item>-->
<!--    </van-grid>-->

    <van-grid column-num="2" :border="false" v-for="(e,i) in orderDetailObj.orderGoodDetails" :key="i">
      <van-grid-item>
        <van-image :src="baseURL+'/img/download/'+orderDetailObj.goodImgs[i].imgUrl" width="100px"></van-image>
      </van-grid-item>
      <van-grid-item><p>x {{ e.goodAmount }}</p></van-grid-item>
    </van-grid>

<!--    <van-cell>价格:¥{{orderDetailObj.orderPrice}}</van-cell>-->

<!--    <van-submit-bar :price="orderDetailObj.orderPrice*100" :button-text="$t('message.confirmOrder')" @submit="confirmOrder" v-if="orderDetailObj.orderState==0"/>-->

    <van-password-input
        :value="value"
        :gutter="10"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
    />


  </div>
</template>

<script>

import axios from "axios";

const coupon = {
  available: 1,
  condition: '无使用门槛\n最多优惠12元',
  reason: '',
  value: 150,
  name: '优惠券名称',
  startAt: 1489104000,
  endAt: 1514592000,
  valueDesc: '1.5',
  unitDesc: '元',
};

export default {
  name: "OrderDetailView",
  data() {
    return {
      chosenCoupon: -1,
      coupons: [coupon],
      disabledCoupons: [coupon],
      showList: false,

      userInputPayPassword: '',
      showKeyboard: false,

      show: false,
      value: '',

      defaultAddressObj:{
        addressName:null,
        addressTel:null,
        addressProvince:null,
        addressCity:null,
        addressCounty:null,
        addressDetail:null,
      },

      goodList:[],

      // //是否显示优惠券列表
      // isShowCouponList:true,
      // //是否显示订单创建时间
      // isShowOrderCreateTime:true,
      // //是否显示订单确认时间
      // isShowOrderConfirmTime:true,
      // //是否显示订单支付时间
      // isShowPayTime:true,
      // //是否显示订单物流号
      // isShowOrderLogistics:true,
      // //是否显示支付方式
      // isShowPayType:true,
      // //是否显示订单状态
      // isShowOrderState:true,
      // //是否显示支付状态
      // isShowPayState:true,

      orderDetailObj:{},
      //订单状态对应
      orderStateMap:{
        0:'待付款',
        1:'已付款',
        2:'已取消',
        3:'已发货',
        4:'待收货',
        5:'已收货',
        6:'退货'
      },
      baseURL:axios.defaults.baseURL
    };
  },
  methods: {
    confirmOrder() {
      console.log('确认订单')
      this.$router.push('/pay')



    },
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange(code) {
      console.log(code)
      this.coupons.push(coupon);
    },
  },
  created() {
    console.log('订单详情界面')
    // let activityId=this.$route.query.activityId;
    // let goodDetailID=this.$route.query.goodDetailID;
    // let buyAmount=this.$route.query.buyAmount;
    let orderId=this.$route.query.orderId;
    //设置默认收货地址
    axios({
      method:'GET',
      url:'/app/address/selectDefaultAddress',
    }).then(res=>{
      this.defaultAddressObj=res.data.data
    });

    axios({
      method:'get',
      url:'/order/order/lookOrder/',
      params:{
        orderId:orderId
      }
    }).then(res=>{
      console.log(res.data)
      let e = res.data.data.orderCreateTime.split('');
      res.data.data.orderCreateTime=`${e[0]}${e[1]}${e[2]}${e[3]}年${e[5]}${e[6]}月${e[8]}${e[9]}日${e[11]}${e[12]}时${e[14]}${e[15]}分${e[17]}${e[18]}秒`;
      this.orderDetailObj=res.data.data
    })
    //秒杀商品的订单详情页面
    // if (activityId!=null){
    //   // console.log('活动'+activityId+'秒杀商品的订单详情页面')
    //   console.log('秒杀商品的订单详情页面')

    //   //显示要秒杀的商品
    //   axios.get(`/GoodModule/frontshop/activity/${activityId}`).then(res => {
    //     // console.log(res.data)
    //     let obj=res.data
    //     this.goodList.push({
    //       goodId:obj.goodId,
    //       imgUrl:`${axios.defaults.baseURL}/ImgModule/imgs/${obj.imgUrl}`,
    //       buyAmount:1
    //     })
    //   })
    //   //
    // }
    //
    // //普通商品的订单详情界面
    // if (goodDetailID!=null){
    //   console.log('普通商品的订单详情界面')
    //   console.log(goodDetailID)
    //   console.log(buyAmount)
    // }



  }
}
</script>

<style>

</style>
