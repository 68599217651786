<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {Locale} from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';

export default {
  created() {
    this.$i18n.locale=localStorage.getItem('language')==='zh'||localStorage.getItem('language')==null?'zh':'en'
    if (localStorage.getItem('language')==='zh'||localStorage.getItem('language')==null){
      Locale.use('zh-CN', zhCN);
    }else{
      Locale.use('en-US', enUS);
    }
  }
}
</script>

<style>
</style>
