<template>
<div>

  <van-nav-bar
      :title="$t('message.searchResult')"
      :left-text="$t('message.back')"
      left-arrow
      @click-left="$router.push('/index')"
  />

  <van-list
      :v-model="loading"
      :finished="finished"
      :finished-text="$t('message.noMore')"
      @load="onLoad"
  ><van-grid square column-num="2" :border="false">
      <van-grid-item v-for="(e,i) in list" :key="i">
        <van-image :src="e.imgUrl" width="70%" height="70%" @click="$router.push({path:'/goodDetail',query:{type:'normal',goodId:e.goodId}})"></van-image>
        <div style="font-size: small">{{ e.goodName }}</div>
      </van-grid-item>
    </van-grid>
  </van-list>

</div>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchView",
  data(){
    return {
      loading: false,
      finished: true,
      list: [],
      pageNum:1,
    }
  },
  methods:{
    onLoad:function () {
      console.log('log')
    }
  },
  created() {

    let searchContent=this.$route.query.searchContent
    console.log('搜索',searchContent)
    axios({
      method: 'GET',
      url: `/app/good/searchGoodVo/${this.pageNum}/10/${searchContent}`,
    }).then(res => {
      console.log(res.data)
      for (let e of res.data.data) {
        e.imgUrl=`${axios.defaults.baseURL}/img/download/${e.imgUrl}`
      }
      this.list=res.data.data
    })
  }
}
</script>

<style scoped>

</style>
