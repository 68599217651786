<template className="hideButton">

  <div>
    <van-nav-bar
        :title="$t('message.address')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <van-address-list
        @select="updateDefaultAddress"
        v-model="defaultAddressId"
        :list="addressList"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
    />
  </div>


</template>

<script>
import {Toast} from "vant";
import axios from "axios";

export default {
  name: "AddressView",
  data() {
    return {

      defaultAddressId: '1',
      addressList: [
        // {
        //   id: '1',
        //   name: '张三',
        //   tel: '13000000000',
        //   address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
        //   // isDefault: true,
        // },
      ],
    };
  },
  methods: {
    onAdd() {
      // Toast('新增地址');
      this.$router.push({path: '/addressDetail', query: {operation: 'addAddress'}})
    },
    onEdit(e, i) {
      // Toast('编辑地址:' + index);
      console.log(e, i)
      this.$router.push({path: '/addressDetail', query: {operation: 'editAddress', address: JSON.stringify(e)}})
    },
    updateDefaultAddress: function (e, i) {
      // console.log(e,i)
      let addressId = e.id;

      //修改用户默认地址
      axios({
        method: 'PUT',
        url: '/app/address/updateDefaultAddress',
        data: {
          defaultAddressId: addressId,
        }
      }).then(res => {
        console.log(res.data)
      })

      for (let e of this.addressList) {
        e.isDefault = false;
      }
      this.addressList.unshift(this.addressList.splice(i, 1)[0])
      e.isDefault = true;
      Toast.success('修改默认地址成功')
    },
  },
  watch: {},
  computed: {},
  created() {

    console.log('地址列表')

    axios({
      method: 'GET',
      url: '/app/address/selectAll',
    }).then(res => {
      // console.log(JSON.stringify(res.data))

      console.log(res.data)
      this.defaultAddressId = res.data.data.defaultAddressId.toString()
      for (let e of res.data.data.addressList) {
        this.addressList.push({
          id: e.addressId.toString(),
          name: e.addressName,
          tel: e.addressTel,
          areaCode: e.addressAreaCode,
          address: `${e.addressProvince}${e.addressCity}${e.addressCounty}${e.addressDetail}`,
          origin: e,
        })
        if (e.addressId == this.defaultAddressId) {
          this.addressList[this.addressList.length - 1].isDefault = true
          this.addressList.unshift(this.addressList.splice(this.addressList.length - 1, 1)[0])
        }
      }

      console.log(this.addressList)
    })


  }

}
</script>

<style scoped>


</style>
