<template className="">

  <div>
    <van-nav-bar
        :title="$t('message.coupon')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />

    <van-tabs>
      <!--      领取优惠券-->
      <van-tab :title="$t('message.claimCoupons')">
        <div v-for="(e,i) in dataList" :key="i">
          <van-panel :title=e.kindName :desc="$t('message.expirationTime')+':'+e.couponEndtime" :status="e.couponMoney+'¥'"
                     style="margin-top: 20px;">
            <div style="font-size: 12px;margin-left: 15px;float:left; ">{{$t('message.couponLimit')}}:{{ e.couponLimit }}¥</div>
            <van-button type="primary" style="float:right;margin-right: 15px;" size="mini" @click="receiveClick(e)" :disabled="true" v-if="jude[i]==false">
              {{$t('message.collected')}}
            </van-button>
            <van-button type="primary" style="float:right;margin-right: 15px;" size="mini" @click="receiveClick(e)" v-else>
              {{$t('message.collect')}}
            </van-button>
          </van-panel>
          <br>
        </div>
      </van-tab>

      <!--     我的优惠券 -->
      <van-tab :title="$t('message.myCoupons')">
        <div v-for="(e,i) in  myCouponList" :key="i">
          <van-panel :title=e.kindName :desc="$t('message.expirationTime')+':'+e.couponExpireTime" :status="e.couponMoney+'¥'"
                     style="margin-top: 20px;">
            <div style="font-size: 12px;margin-left: 15px;float:left; ">{{$t('message.couponLimit')}}:{{ e.couponLimit }}¥</div>
          </van-panel>
          <br>
        </div>
      </van-tab>

      <!--    不可用优惠券  -->
      <van-tab :title="$t('message.usedExpired')">
        <div v-for="(e,i) in   expireCouponList" :key="i">
          <van-panel :title=e.kindName :desc="$t('message.expirationTime')+':'+e.couponExpireTime" :status="e.couponMoney+'¥'"
                     style="margin-top: 20px;">
            <div style="font-size: 12px;margin-left: 15px;float:left; ">{{$t('message.couponLimit')}}:{{ e.couponLimit }}¥</div>
          </van-panel>
          <br>
        </div>
      </van-tab>

    </van-tabs>
  </div>
</template>

<script>

import {Toast} from "vant";

export default {
  name: "CouponView",
  data() {
    return {
      expireCouponList: [],
      myCouponList: [],
      dataList: [],
      jude:[],
    }
  },
  methods: {
    receiveClick: function (row) {//领取优惠券
      console.log("2222222222", row);
      this.$axios({method: "post", url: `/coupon/usercoupon/userGetCoupons/${row.couponId}`})
          .then(res => {
            console.log(res.data.code)
            if (res.data.code == 200) {
              console.log("领取成功！" + res.data)
              for (let i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i].couponId == row.couponId) {
                  this.dataList.splice(i, 1);
                  this.myCouponList.push(row)
                  return
                }
              }
            }
            if(res.data.code == 300){
              Toast('您已领过该优惠券');
            }
          })
          .catch(err => {
            console.log(err)
            console.log("出错了")
          })

    }
  },

  created() {
    this.$axios({method: "get", url: `/coupon/usercoupon/userAllCoupons`})//获取用户的未过期的优惠券
        .then(res => {
          console.log(res.data)
          if (res.data.code == 200) {
            let li = res.data.data.userCouponList;
            for (let e of li) {
              e.couponExpireTime = e.couponExpireTime.replace('T', ' ')
            }
            this.myCouponList = li;
          }
          this.$axios({method: "get", url: `/coupon/coupon/noEndCoupons`,})//获取所有的优惠券
              .then(res => {
                if (res.data.code == 200) {
                  let li = res.data.data.noEndCoupons
                  for (let e of li) {
                    e.couponEndtime = e.couponEndtime.replace('T', ' ')
                  }
                  //在这里进行判断，如果我的优惠券和全部优惠券一样，就对按钮进行不可用，已领用
                  for(let i in this.myCouponList){
                    for(let j in li){
                      if(this.myCouponList[i].couponId==li[j].couponId){
                        this.jude[j]=false
                      }
                    }
                  }
                  this.dataList = li;
                }
              })
        })

    this.$axios({method: "get", url: `/coupon/usercoupon/userEndCoupons`})//获取用户的使用/过期的的优惠券
        .then(res => {
          console.log("已使用")
          console.log(res.data)
          if (res.data.code == 200) {
            let li = res.data.data.userEndCouponList;
            for (let e of li) {
              e.couponExpireTime = e.couponExpireTime.replace('T', ' ')
            }
            this.expireCouponList = li;
          }
        })


  }


}

</script>
