import { render, staticRenderFns } from "./VipView.vue?vue&type=template&id=261be500&scoped=true&"
import script from "./VipView.vue?vue&type=script&lang=js&"
export * from "./VipView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261be500",
  null
  
)

export default component.exports