<template>
  <div>

<!--    <p>{{ $t("message.confirm") }}</p>-->
<!--    <button @click="localTest">本地按钮</button>-->
<!--    <button @click="remoteTest">远程按钮</button>-->
    <router-view></router-view>

    <div>
      <van-tabbar v-model="active" placeholder>
        <van-tabbar-item icon="home-o" @click="jumpTo('/index')">{{ $t("message.index") }}</van-tabbar-item>
<!--        <van-tabbar-item icon="chat-o" @click="jumpTo('/message')">{{ $t("message.message") }}</van-tabbar-item>-->
        <van-tabbar-item icon="shopping-cart-o" @click="jumpTo('/shopcar')">{{ $t("message.shopcar") }}</van-tabbar-item>
        <van-tabbar-item icon="gem-o" @click="jumpTo('/vip')">{{ $t("message.vip") }}</van-tabbar-item>
        <van-tabbar-item icon="user-circle-o" @click="jumpTo('/mine')">{{ $t("message.mine") }}</van-tabbar-item>
      </van-tabbar>
    </div>




  </div>

</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      active:0,
      searchText:''
    }
  },
  watch:{

  },
  methods:{
    jumpTo:function (path) {
      // console.log('跳转')
      this.$router.push(path)
    },
  }
}
</script>

<style>
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #000000;
}
.block {
  display: inline-block;
  width: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #000000;
  border-radius: 5px;
}
</style>
