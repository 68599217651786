import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginState: function () {
            let jwt = window.localStorage.getItem('jwt');
            return jwt != null;
        }(),
    },
    getters: {
        getLoginState: state => state.loginState,
    },
    mutations: {
        syncSetLoginState: (state, payload) => state.loginState = payload,
    },
    actions: {
        setLoginState: (injectee, payload) => injectee.commit('syncSetLoginState', payload),
    },
    modules: {}
})
