<template>
  <div>
    <van-nav-bar
        :title="$t('message.confirmOrder')"
        :left-text="$t('message.back')"
        left-arrow
        @click-left="$router.back()"
    />
    <van-cell-group>
      <van-cell
          :label="defaultAddressObj.addressProvince+' '+defaultAddressObj.addressCity+' '+defaultAddressObj.addressCounty+' '+defaultAddressObj.addressDetail">
        <template #title>
          <span>{{ defaultAddressObj.addressName }}</span>
          &nbsp;
          <span>{{ defaultAddressObj.addressTel }}</span>
        </template>
        <template #right-icon>
          <van-button size="small" type="primary" @click="$router.push({path:'/Address',query:{from:'orderDetail'}})">
            {{$t('message.updateDefaultAddress')}}
          </van-button>
        </template>
      </van-cell>
    </van-cell-group>
    <van-grid column-num="3" :border="false" v-for="(e,i) in buyDetail" :key="i">
      <van-grid-item>
        <van-image :src="e.imgUrl" width="100px"></van-image>
      </van-grid-item>
      <van-grid-item><p style="font-size: small">¥ {{ e.goodPrice }}</p></van-grid-item>
      <van-grid-item><p style="font-size: medium">x {{ e.buyAmount }}</p></van-grid-item>
    </van-grid>
<!--    <van-cell>运费:¥0</van-cell>-->
    <!-- 优惠券单元格 -->
    <van-coupon-cell
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        @click="showList = true"
        v-if="isShowCoupon"
    />
    <!-- 优惠券列表 -->
    <van-popup
        v-model="showList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px;"
    >
      <van-coupon-list
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          @change="onChange"
          @exchange="onExchange"
          v-if="isShowCouponList"
          :show-exchange-bar=false
      />
    </van-popup>
    <van-grid column-num="3" :border="false" v-for="e in goodList" :key="e.goodId">
      <van-grid-item>
        <van-image :src="e.imgUrl"></van-image>
      </van-grid-item>
      <van-grid-item><p>x {{ e.buyAmount }}</p></van-grid-item>
    </van-grid>
    <!--不使用优惠券-->
    <van-cell v-if="chosenCoupon==-1">{{$t('message.orderPrice')}} ¥<a>{{ orderPrice }}</a></van-cell>
    <van-cell v-else>{{$t('message.orderPrice')}} ¥<a
        style="text-decoration: line-through">{{ orderPrice + coupons[chosenCoupon].value / 100 }}</a> {{$t('message.discountPrice')}}
      ¥{{ orderPrice }}
    </van-cell>
    <van-submit-bar :price="orderPrice*100" :button-text="$t('message.confirmOrder')" @submit="confirmOrder" :loading="isLoading"/>
    <van-password-input
        :value="value"
        :gutter="10"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
    />
  </div>
</template>

<script>
import axios from "axios";
import {Notify, Toast} from "vant";

// const coupon = {
//   available: 0,
//   condition: '无使用门槛\n最多优惠12元',
//   reason: '222222222',
//   value: 150,
//   name: '优惠券名称',
//   startAt: 1489104000,
//   endAt: 1489104000,
//   valueDesc: '1.5',
//   unitDesc: '元',
// };

export default {
  name: "ConfirmOrderView",
  data() {
    return {
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      showList: false,
      userInputPayPassword: '',
      showKeyboard: false,
      show: false,
      value: '',
      defaultAddressObj: {
        addressName: null,
        addressTel: null,
        addressProvince: null,
        addressCity: null,
        addressCounty: null,
        addressDetail: null,
      },
      goodList: [],
      // //是否显示优惠券列表
      isShowCouponList: true,
      //是否展示优惠券选择界面
      isShowCoupon:true,
      buyDetail: [],
      deleteFromShopcar: '',
      isLoading: false,

      contentId:'',

    };
  },
  methods: {
    confirmOrder() {
      this.isLoading = true;
      if (this.buyDetail.length == 1) {
        console.log('确认单个商品订单')
        console.log({
          couponId: this.couponId,
          goodDetailId: this.buyDetail[0].goodDetailId,
          buyAmount: this.buyDetail[0].buyAmount,
          deleteFromShopcar: this.deleteFromShopcar,
        })
        if (this.buyDetail[0].goodDetailId == 99) {
          console.log('会员订单')
          axios({
            method: 'post',
            url: '/order/order/genrateMember',
            params: {
              couponId: null,
              goodDetailId: this.buyDetail[0].goodDetailId,
              buyAmount: this.buyDetail[0].buyAmount,
              orderPrice: this.buyDetail[0].goodPrice,
            },
          }).then(res => {
            this.$router.push({path: '/pay',
              query: {
                orderId: res.data.data.orderId,
                orderPrice: this.buyDetail[0].goodPrice,
                goodDetailId: this.buyDetail[0].goodDetailId
              }
            })
          })
        }

        else if (this.type=='seckill'){
          console.log('秒杀订单')
          axios({
            method: 'post',
            url: '/seckill/activity/seckill/',
            data: {
              goodDetailId: this.buyDetail[0].goodDetailId,
              contentId:this.contentId,
            },
          }).then(res => {
            if (res.data.code==200){
              Notify({type: 'success', message: '秒杀成功'});
              // this.$router.push({path: '/pay', query: {orderId: res.data.data.orderId, orderPrice: res.data.data.orderPrice}})
              this.$router.push({path: '/pay', query: {orderId: res.data.data.orderId, orderPrice: 0}})
            }else{
              Notify({type: 'danger', message: '您有一笔该商品的未付款订单'});
              this.$router.push({path: '/pay', query: {orderId: res.data.data.orderId, orderPrice: 0}})
            }
          })
        }

        else {
          axios({
            method: 'POST',
            url: '/normal/good/buy/single',
            data: {
              couponId: this.couponId,
              userCouponId:this.userCouponId,
              goodDetailId: this.buyDetail[0].goodDetailId,
              buyAmount: this.buyDetail[0].buyAmount,
              deleteFromShopcar: this.deleteFromShopcar,
            },
          }).then(res => {
            if (res.data.code == 250) {
              console.log(res.data)
              Toast.fail(res.data.msg)
              this.isLoading = false
            } else {
              console.log(res.data)
              this.isLoading = false
              let orderId = res.data.data.orderId;
              let orderPrice = res.data.data.orderPrice;
              Toast.success(res.data.msg)
              this.$router.push({path: '/pay', query: {orderId, orderPrice}})
            }
          })
        }
        return;
      }

      if (this.buyDetail.length > 1) {
        console.log('确认多个商品订单')
        console.log(this.buyDetail)
        let orderRequests = [];
        for (let e of this.buyDetail) {
          e.deleteFromShopcar = this.deleteFromShopcar;
          orderRequests.push(e)
        }
        axios({
          method: 'POST',
          url: '/normal/good/buy/multiple',
          data: {
            couponId: this.couponId,
            userCouponId:this.userCouponId,
            orderRequests,
          },
        }).then(res => {
          if (res.data.code == 250) {
            console.log(res.data)
            Toast.fail(res.data.msg)
            this.isLoading = false
          } else {
            this.isLoading = false
            console.log(res.data)
            let orderId = res.data.data.orderId;
            let orderPrice = res.data.data.orderPrice;
            this.$router.push({path: '/pay', query: {orderId, orderPrice}})
          }
        })
        return
      }

    },
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange(code) {
      console.log(code)
    },
    //查询用户优惠券
    selectUserCoupons(){
      //查询用户优惠券
      // console.log('查询用户优惠券')
      let goodKindIdSet=new Set()
      console.log(this.buyDetail)
      for (let obj of this.buyDetail) {
        goodKindIdSet.add(parseInt(obj.goodKindId))
      }
      console.log(goodKindIdSet)

      axios({
        method: 'post',
        url: '/coupon/usercoupon/userBuyOrder',
        // url: 'http://localhost:8084/coupon/usercoupon/userBuyOrder',
        data:Array.from(goodKindIdSet)
      }).then(res => {
        // console.log('查询用户优惠券')
        console.log(res.data)
        for (let e of res.data.data.orderCouponList) {
          this.coupons.push({
            couponId:e.couponId,
            condition: `优惠${e.couponMoney}元`,
            value:e.couponMoney*100,
            startAt: new Date(e.couponAcquireTime).getTime(),
            endAt: new Date(e.couponExpireTime).getTime(),
            valueDesc: e.couponMoney,
            unitDesc: '元'
          })
        }
      })

    },
    selectUserDefaultAddress(){
      axios({
        method: 'GET',
        url: '/app/address/selectDefaultAddress',
      }).then(res => {
        this.defaultAddressObj = res.data.data
      })
    },

  },
  watch: {
    // chosenCoupon: function (nv) {
    //   console.log('选择的优惠券',nv)
    // }
  },
  computed: {
    orderPrice: function () {
      let v = 0;
      for (let e of this.buyDetail) {
        v += e.goodPrice * e.buyAmount
      }
      //选择了优惠券
      if (this.chosenCoupon != -1) {
        v -= this.coupons[this.chosenCoupon].value / 100
      }
      return v;
    },
    couponId:function () {
      if (this.chosenCoupon == -1){
        return null
      }else {
        return this.coupons[this.chosenCoupon].couponId
      }
    },
    userCouponId:function () {
      if (this.chosenCoupon == -1){
        return null
      }else {
        return this.coupons[this.chosenCoupon].userCouponId
      }
    }
  },
  async created() {
    let buyDetail = [];
    if (this.$route.query.buyDetail) {
      buyDetail = JSON.parse(this.$route.query.buyDetail);
      this.buyDetail = buyDetail
    }
    console.log(buyDetail)

    this.deleteFromShopcar = this.$route.query.deleteFromShopcar;
    this.seckillOrder = this.$route.query.seckillOrder;
    this.type = this.$route.query.type;
    this.contentId = this.$route.query.contentId
    // console.log('confirm type',this.type)
    if (this.seckillOrder == 'yes' || this.type == 'seckill' || this.type == 'activity' || this.type == 'vip') {
      this.isShowCoupon = false
    }

    if (buyDetail[0].goodId != 99) {
      //查询用户优惠券
      this.selectUserCoupons()
    }
    //查询用户默认地址
    this.selectUserDefaultAddress()
    //查询商品图片的信息
    for (let e of buyDetail) {
      await axios({
        method: 'GET',
        url: `/app/selectImg/${e.goodId}`,
      }).then(res => {
        e.imgUrl = `${axios.defaults.baseURL}/img/download/${res.data}`
        console.log('查询图片成功')
      })
    }
    this.buyDetail = buyDetail
  }
}
</script>

<style scoped>

</style>
